import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ExpandedIndex,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  Avatar,
  Select,
  Circle,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import _cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';
import { parseQuestionLabel } from '../../utils/parseQuestionLabel';

import { userAtom } from 'src/atoms';
import {
  Answer,
  ContractType,
  Negotiation,
  NegotiationHistory,
  NegotiationPanelData,
  NegotiationStep,
  NegotiationTerm,
  RoleResponse
} from 'src/interfaces';
import { mergeAndSanitizeAnswers } from 'src/utils/mergeAndSanitizeAnswers';
import { parseAnswers } from 'src/utils/parseAnswers';
import { parseConditions } from 'src/utils/parseConditions';
import { ChartaProse } from 'src/components/core/ChartaProse';
import { ChartaText } from 'src/components/core/ChartaText';
import { ChartaField } from 'src/components/form/ChartaField';
import { Check } from 'src/components/icons/Check';
import { Dropdown } from 'src/components/icons/Dropdown';
import { Info } from 'src/components/icons/Info';
import { Notification } from 'src/components/icons/Notification';
import cloneDeep from 'lodash/cloneDeep';
import { addCommasToNumber } from '../../utils/addCommasToNumber';
import { negotiationPanelTheme, V2TextStyles, V2Theme } from 'src/theme/theme';
import {
  ProcessingIcon,
  ProcessCompletedIcon,
  ChevronDownSmallIcon,
  ChevronDownIcon,
  QuestionIconFilled
} from 'src/v2/DashboardIcons';
import { ButtonSecondary, ButtonPrimary } from 'src/v2/Button';
import {
  InfoModal,
  InfoModalHeader,
  InfoModalTitle,
  InfoModalContent
} from 'src/components/core/InfoModal';
import { validate } from 'src/utils/validate';

interface NegotiationPanelProps extends BoxProps {
  negotiation: Negotiation;
  isFirstCounter: boolean;
  onSubmitAnswers: (answers: Answer[]) => void;
  onViewContract: () => void;
  onContractStarted?: (date: string) => void;
  onTitleChange?: (title: string) => void;
  contractData: any;
}

interface AccordionState {
  index: number;
  steps: NegotiationStep[];
}

export const NegotiationPanel: React.FC<NegotiationPanelProps> = ({
  negotiation,
  isFirstCounter,
  onSubmitAnswers,
  onViewContract,
  onContractStarted,
  onTitleChange,
  contractData,
  ...props
}) => {
  const bg = negotiationPanelTheme.boxBackground;
  const termBackground = '#1B1B1B';
  const headerColor = negotiationPanelTheme.headingTextColor;
  const highlightColor = negotiationPanelTheme.highlightColor;

  const notificationColor = useColorModeValue('#DE970D', '#F6C547');
  const successColor = useColorModeValue('#159700', '#7BFF66');
  const tableBorderColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const tableTextColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const panelDividerColor = useColorModeValue(
    'rgba(30, 32, 41, 0.8)',
    'rgba(255, 255, 255, 0.1)'
  );
  const offerColor = useColorModeValue('#DE970D', '#F6C547');

  const isContractSummary =
    negotiation.status !== 'active-contract' &&
    negotiation.status !== 'created';
  const [user] = useAtom(userAtom);
  const [newAnswers, setNewAnswers] = useState<Answer[]>([]);
  const [viewStatuses, setViewStatuses] = useState<Record<string, boolean>>({});
  const [historyStatuses, setHistoryStatuses] = useState<
    Record<string, boolean>
  >({});
  const [accordionState, setAccordionState] = useState<AccordionState>({
    index: -1,
    steps: []
  });
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });
  const [panelData, setPanelData] = useState<NegotiationPanelData>({
    subject: '',
    subjectName: '',
    steps: [],
    referenceAnswers: []
  });

  const termsNegotiated = panelData.steps
    .map((step) => step.counteredCount)
    .reduce((acc, curr) => acc + curr, 0);
  const termsRemaining =
    panelData.steps
      .map((step) => step.negotiatingCount)
      .reduce((acc, curr) => acc + curr, 0) - termsNegotiated;

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm();
  watch();

  const {
    initiator,
    receiver,
    latestInitiatorResponses,
    latestReceiverResponses,
    receiverResponses,
    initiatorResponses,
    createdAt,
    currentResponder,
    contractType
  } = negotiation;
  const {
    name: contractTypeName,
    form: { steps },
    conditions,
    validations,
    nonNegotiable,
    subjectType,
    subjectFieldName
  } = contractType as ContractType;
  const isUserInitiator = user!.email === initiator.email;
  let isUserResponding =
    (isUserInitiator && currentResponder === 'initiator') ||
    (!isUserInitiator && currentResponder === 'receiver');

  const getUserLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestInitiatorResponses.answers
      : latestReceiverResponses?.answers || [];
  };

  const getOtherPartyLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestReceiverResponses?.answers || []
      : latestInitiatorResponses.answers;
  };

  const getReferenceAnswers = (
    baseAnswers: Answer[],
    mainAnswers: Answer[]
  ): Answer[] => {
    const base = [...baseAnswers];
    const main = [...mainAnswers];

    for (let i = 0; i < main.length; i++) {
      const j = base.findIndex(
        (answer: Answer) => answer.fieldId === main[i].fieldId
      );
      if (j > -1) {
        base[j] = main[i];
      } else {
        base.push({ ...main[i] });
      }
    }
    return base;
  };

  const formatHistory = (
    response: RoleResponse,
    party: any,
    id: string
  ): NegotiationHistory | null => {
    if (contractType != null && (contractType as ContractType)?.oneSided) {
      return null;
    }
    const answer = response.answers.find(
      ({ questionId }: any) => questionId === id
    );

    if (answer) {
      return {
        name: `${party.firstName} ${party.lastName}`,
        role: party.role,
        imageUrl: party.profileImageUrl,
        value: answer.value,
        date: new Date(response.date).toLocaleDateString()
      };
    } else {
      return null;
    }
  };

  const getNegotiationTermHistory = (
    questionId: string
  ): NegotiationHistory[] => {
    let history: NegotiationHistory[] = [];

    const newResponse = {
      date: new Date().toDateString(),
      answers: [...newAnswers]
    };

    const newInitiatorResponses = [...initiatorResponses];
    const newReceiverResponses = [...receiverResponses];

    if (isUserInitiator) {
      newInitiatorResponses.push(newResponse);
    } else {
      newReceiverResponses.push(newResponse);
    }

    let initiatorDone = false;
    let receiverDone = false;
    let count = 0;

    while (!initiatorDone || !receiverDone) {
      if (count > newInitiatorResponses.length - 1) {
        initiatorDone = true;
      } else {
        const initiatorResponse = newInitiatorResponses[count];
        const initiatorAnswer = formatHistory(
          initiatorResponse,
          initiator,
          questionId
        );
        if (initiatorAnswer) {
          history.push(initiatorAnswer);
        }
      }

      if (count > newReceiverResponses.length - 1) {
        receiverDone = true;
      } else {
        const receiverResponse = newReceiverResponses[count];
        const receiverAnswer = formatHistory(
          receiverResponse,
          receiver,
          questionId
        );
        if (receiverAnswer) {
          history.push(receiverAnswer);
        }
      }
      count = count + 1;
    }
    return history;
  };

  const checkIfDummyField = (answer: any, contractType: any) => {
    if (
      contractType?.listOfFieldsToPlaceDummyDataIn != null &&
      Array.isArray(contractType?.listOfFieldsToPlaceDummyDataIn)
    ) {
      for (const dummyField of contractType.listOfFieldsToPlaceDummyDataIn) {
        if (answer.fieldId === dummyField.fieldId) {
          return true;
        }
      }
    }
    return false;
  };

  const addIsDummyToAnswerArray = (answers: any) => {
    return _cloneDeep(answers).map((answer: any) => {
      // return answer;
      const isDummyField = checkIfDummyField(answer, contractType);
      if (!isDummyField) {
        return answer;
      }
      return {
        ...answer,
        isDummyField
      };
    });
  };

  useEffect(() => {
    //Get user latest answers
    const answersToValidate = addIsDummyToAnswerArray(newAnswers);
    const latestAnswers = getUserLatestAnswers();
    const mappedLatestAnswers = addIsDummyToAnswerArray(latestAnswers);
    const userAnswers = mergeAndSanitizeAnswers(
      mappedLatestAnswers,
      answersToValidate,
      conditions
    );

    //Get other party latest answers
    const otherPartyAnswers = getOtherPartyLatestAnswers();

    //Reference answers is always in relation to current user?
    const referenceAnswers = getReferenceAnswers(
      otherPartyAnswers,
      userAnswers
    );

    //Initialize panelData object
    const newPanelData: NegotiationPanelData = {
      subject: subjectType,
      subjectName: parseAnswers(referenceAnswers)[subjectFieldName],
      steps: [],
      referenceAnswers: referenceAnswers
    };

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      newPanelData.steps.push({
        id: step.id,
        counteredCount: 0,
        negotiatingCount: 0,
        terms: []
      });

      for (let j = 0; j < step.subSteps.length; j++) {
        const subStep = step.subSteps[j];
        for (let k = 0; k < subStep.questions.length; k++) {
          const question = subStep.questions[k];

          //Skip this question/term if it is hidden
          if (question.hidden) {
            continue;
          }

          const isNonNegotiable = nonNegotiable.indexOf(question.field.id) > -1;

          //Skip this question/term if condition is not met
          const condition = conditions.questions?.find(
            (c: any) => c[question.id] !== undefined
          );

          if (
            !isNonNegotiable &&
            condition &&
            !parseConditions(condition, parseAnswers(referenceAnswers))
          ) {
            continue;
          }

          const userAnswer = userAnswers.find(
            (answer) => answer.questionId === question.id
          );
          const otherPartyAnswer = otherPartyAnswers.find(
            (answer) => answer.questionId === question.id
          );

          /**
           * Commented this block out on April 24, 2023
           * I assume dthis was no longer necessary
           * and that it was a relic of the previous
           * version of the negotiation panel.
           * There should not be a neeed to skip a question if
           * neither party has responded, given the
           * conditional checks were done prior
           */
          //Skip this question/term if neither party has answered
          // if (
          //   !isNonNegotiable &&
          //   !userAnswer?.value &&
          //   !otherPartyAnswer?.value
          // ) {
          //   continue;
          // }

          const term: NegotiationTerm = {
            step: step.id,
            subStep: subStep.id,
            question: question.id,
            fieldId: question.field.id,
            status: null,
            label: question.label,
            conditionalLabels: question.conditionalLabels,
            tooltip: question.tooltip,
            prefix: question.field.prefix,
            postfix: question.field.postfix,
            field: question.field //Hack to get field for counter-offer form control
          };

          const isZipCode = term.label?.toLowerCase()?.includes('code'); //Hack to prevent commas from being added to Zip code          const isCountered = userAnswer?.status === 'new';
          const isCountered = userAnswer?.status === 'new';
          const isReceiverFirst =
            (userAnswer?.value === undefined &&
              otherPartyAnswer?.value === undefined) ||
            (userAnswer?.value === null && otherPartyAnswer?.value === null);
          /**
           * In some cases the receiver will be the first person to respond to a question
           * e.g. the initiator skipped the question based on conditional logic
           * In these cases when the receiver is about to answer the question
           * i.e. negotiate, both parties' answers will be undefined.
           * The null check is done also just to be safe.
           */
          const isNegotiating = userAnswer?.value !== otherPartyAnswer?.value;
          const isFinal = userAnswer?.value === otherPartyAnswer?.value;

          if (isNonNegotiable) {
            const initiatorValue = isUserInitiator
              ? userAnswer?.value
              : otherPartyAnswer?.value;
            const receiverValue = isUserInitiator
              ? otherPartyAnswer?.value
              : userAnswer?.value;

            /**
             * Gets the role for this non-negotiable question by checking the condition
             * or$ conditons are not checked because it wouldn't make sense for non-negotiable
             * The condition check would not work for complicated conditons e.g. nested and$
             * We need to bear this in mind when creating non-negotiables. Perhaps we should
             * explicitly state roles in the contract type definition
             */
            let role: string = get(condition, `${question.id}.role.eq$`);

            if (!role) {
              const arr: any[] | undefined = get(
                condition,
                `${question.id}.and$`
              );
              if (arr) {
                let arrRole = arr.find((a) => a.role !== undefined);

                if (!arrRole) {
                  role =
                    initiatorValue !== undefined
                      ? initiator?.role
                      : receiver?.role;
                } else {
                  role = get(arrRole, 'role.eq$');
                }
              } else {
                role =
                  initiatorValue !== undefined
                    ? initiator?.role
                    : receiver?.role;
                console.log(
                  condition,
                  get(condition, `${question.id}.role.eq$`)
                );
              }
            }

            let value =
              role === initiator.role ? initiatorValue : receiverValue;

            const newTerm: any = {
              ...term,
              role: role != null ? role.toUpperCase() : '',
              value: addCommasToNumber(value, isZipCode)
            };

            if (
              !isContractSummary &&
              isUserResponding &&
              ((isUserInitiator && role === initiator.role) ||
                (!isUserInitiator && role === receiver.role))
            ) {
              if (
                condition &&
                !parseConditions(condition, parseAnswers(referenceAnswers))
              ) {
                continue;
              }

              if (isCountered) {
                newPanelData.steps[i].counteredCount++;
                newPanelData.steps[i].negotiatingCount++;
                newTerm.status = 'non-negotiable-answered';
                newTerm.userAnswer = addCommasToNumber(
                  userAnswer.value,
                  isZipCode
                );
              } else if (
                (isUserInitiator &&
                  role === initiator.role &&
                  initiatorValue === undefined) ||
                (!isUserInitiator &&
                  role === receiver.role &&
                  receiverValue === undefined)
              ) {
                newPanelData.steps[i].negotiatingCount++;
                newTerm.status = 'non-negotiable-input';
              } else {
                newTerm.status = 'non-negotiable';
              }
            } else {
              if (
                condition &&
                !parseConditions(condition, parseAnswers(referenceAnswers))
              ) {
                continue;
              }

              newTerm.status = 'non-negotiable';
            }

            newPanelData.steps[i].terms.push(newTerm);
            continue;
          }

          if (isCountered) {
            newPanelData.steps[i].counteredCount++;
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'countered',
              userAnswer: addCommasToNumber(userAnswer.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isReceiverFirst) {
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'receiver-first',
              userAnswer: null,
              otherPartyAnswer: null
            });
            continue;
          }

          if (isNegotiating) {
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'negotiating',
              userAnswer: addCommasToNumber(userAnswer?.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              userAnswerValue: userAnswer?.value,
              otherPartyAnswerValue: otherPartyAnswer?.value,
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isFinal) {
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'final',
              value: addCommasToNumber(userAnswer?.value, isZipCode),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }
        }
      }
    }

    setPanelData(newPanelData);
  }, [
    conditions,
    initiator.role,
    isFirstCounter,
    isUserInitiator,
    isUserResponding,
    negotiation,
    newAnswers,
    nonNegotiable,
    receiver?.role,
    steps,
    subjectFieldName,
    subjectType,
    isContractSummary
  ]);

  useEffect(() => {
    setAccordionState((previous) => {
      if (previous.index === -1) {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      } else {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      }
    });
    if (
      Object.keys(viewStatuses).length === 0 &&
      panelData?.steps?.length > 0 &&
      !isContractSummary
    ) {
      const temp = cloneDeep(viewStatuses);
      for (const step of panelData.steps) {
        temp[step.id] = true;
      }
      setViewStatuses(temp);
    }
  }, [isContractSummary, panelData, viewStatuses]);

  useEffect(() => {
    if (negotiation?.createdAt && onContractStarted) {
      onContractStarted(moment(negotiation.createdAt).format('MMM DD, YYYY'));
    }
  }, [negotiation?.createdAt, onContractStarted]);

  useEffect(() => {
    if (panelData?.subject && panelData?.subjectName && onTitleChange) {
      onTitleChange(`${panelData.subject}: '${panelData.subjectName}'`);
    }
  }, [panelData?.subject, panelData?.subjectName, onTitleChange]);

  const onAcceptOffer = async (questionId: string) => {
    const term = panelData.steps
      .flatMap((step) => step.terms)
      .find((t) => t.question === questionId);

    if (!term) return;

    setValue(term.fieldId, term.otherPartyAnswerValue);

    const result = await trigger(term.fieldId);

    if (!result) {
      return;
    }

    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value: term.otherPartyAnswerValue,
        status: 'new'
      }
    ]);
  };

  const onCounterOffer = async (questionId: string) => {
    const term = panelData.steps
      .flatMap((step) => step.terms)
      .find((t) => t.question === questionId);

    if (!term) return;

    const result = await trigger(term.fieldId);
    if (!result) {
      return;
    }

    const value = getValues()[term.fieldId];

    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value,
        status: 'new'
      }
    ]);
  };

  const onEditCounterOffer = async (term: any) => {
    const answers = [...newAnswers].filter(
      (answer: Answer) => answer.fieldId !== term.fieldId
    );
    setNewAnswers(answers);
  };

  const onToggleViewStatus = (id: string) => {
    const status = viewStatuses[id];
    if (status === undefined) {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleHistoryStatus = (id: string) => {
    const status = historyStatuses[id];
    if (status === undefined) {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleAccordion = (index: number) => {
    if (index === -1) {
      setAccordionState({
        index: -1,
        steps: [...panelData.steps]
      });
    } else {
      // const filteredSteps = panelData.steps.filter(
      //   (step, i) => i === index.valueOf()
      // );
      setAccordionState({
        index,
        steps: [...panelData.steps]
      });
    }
  };

  const handleSubmit = async () => {
    onSubmitAnswers(newAnswers);
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };

  return (
    <Flex
      flexDirection="column"
      as="form"
      noValidate
      my={{ base: '24px', lg: '32px' }}
      padding={{ base: '8px 8px 8px 8px', xl: '0 32px' }}
      mx={{ base: '0px', lg: '100px' }}
      h={{ base: '100%', lg: '80%' }}
      flex="1"
      {...props}
    >
      <Flex alignItems="center" gap={{ base: '16px', lg: '24px' }} mb="22px">
        <Flex
          gap={{ base: '10px', lg: '18px' }}
          data-info="terms-remaining-container"
        >
          <Box
            w="30px"
            h="30px"
            bg="#EFEFEF"
            rounded="7px"
            as="span"
            data-info="terms-remaining-count-container"
          >
            <Text
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              css={V2TextStyles.base}
              textAlign="center"
              color="#141414"
              data-info="terms-remaining-count"
            >
              {termsRemaining}
            </Text>
          </Box>
          <Text
            css={V2TextStyles.base}
            color="#141414"
            data-info="terms-remaining-label"
          >
            Terms remaining
          </Text>
        </Flex>

        <Flex gap="18px" data-info="terms-negotiated-container">
          <Box
            w="30px"
            h="30px"
            bg="#EFEFEF"
            rounded="7px"
            as="span"
            data-info="terms-negotiated-count-container"
          >
            <Text
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              css={V2TextStyles.base}
              textAlign="center"
              color="#141414"
              data-info="terms-negotiated-count"
            >
              {termsNegotiated}
            </Text>
          </Box>
          <Text
            css={V2TextStyles.base}
            color="#141414"
            data-info="terms-negotiated-label"
          >
            Terms negotiated
          </Text>
        </Flex>
      </Flex>

      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
        gap="32px"
      >
        <Accordion
          flex="1"
          allowToggle={true}
          variant="panel"
          onChange={onToggleAccordion}
          index={accordionState.index}
        >
          {steps.map((step: any, index: number) => {
            const negotiationStep = accordionState.steps.find(
              (n) => n.id === step.id
            );

            if (!negotiationStep) {
              return <React.Fragment key={step.id}></React.Fragment>;
            }

            return (
              <AccordionItem rounded="0" key={step.id}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      data-test-id={`accordion-button-${step.id}`}
                      border="1px solid #e9e9e9"
                      _expanded={{
                        borderBottomColor: 'transparent',
                        background: '#fff'
                      }}
                      rounded="none"
                      bg="#fff"
                      p={{ base: '16px 14px', lg: '18px 30px' }}
                      _hover={{
                        background: '#f3f3f3'
                      }}
                    >
                      <Flex
                        gap={{ base: '4px', lg: '8px' }}
                        justifyContent="space-between"
                        w="100%"
                      >
                        <Text
                          textAlign="left"
                          css={V2TextStyles.T2}
                          fontWeight="600"
                          lineHeight="32.68px"
                          color={V2Theme.navbar.background}
                        >
                          {step.title}
                        </Text>
                        {negotiationStep.negotiatingCount === 0 ? (
                          <Flex
                            gap="8px"
                            alignItems="center"
                            data-info="all-terms-approved-icon"
                          >
                            <ProcessCompletedIcon />
                            <Text
                              css={V2TextStyles.xs}
                              fontWeight="600"
                              mr="8px"
                            >
                              All approved
                            </Text>
                          </Flex>
                        ) : (
                          <Flex
                            gap="8px"
                            alignItems="center"
                            data-info="terms-remaining-icon"
                          >
                            <ProcessingIcon />
                            <Text
                              color={V2Theme.navbar.background}
                              css={V2TextStyles.xs}
                              fontWeight="600"
                            >
                              Terms of remaining{' '}
                              <Text
                                minWidth="160px"
                                ml={{ base: '4px', lg: '10px' }}
                                color="#848484"
                                as="span"
                                bg="#f3f3f3"
                                fontSize={{ base: '10px', lg: '12px' }}
                                p={{ base: '4.5px 8px', lg: '4.5px 9px' }}
                                rounded="100%"
                              >
                                {negotiationStep.negotiatingCount -
                                  negotiationStep.counteredCount}
                              </Text>
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel
                      data-test-id={`accordion-panel-testing`}
                      bg="#fff"
                      border="1px solid #e9e9e9"
                      borderTop="none"
                      height="auto"
                      p={{
                        base: '16px',
                        lg: '24px'
                      }}
                      rounded="0"
                      borderRadius="0"
                    >
                      {!isContractSummary && (
                        <Flex pb="28px" alignItems="center" gap="12px">
                          <Text
                            css={V2TextStyles.xs}
                            color={V2Theme.navbar.background}
                            fontWeight={
                              !viewStatuses[negotiationStep.id] ? '600' : '400'
                            }
                          >
                            All
                          </Text>
                          <Switch
                            size="md"
                            onChange={() => onToggleViewStatus(step.id)}
                            isChecked={viewStatuses[negotiationStep.id]}
                            sx={{
                              'span.chakra-switch__track': {
                                backgroundColor: viewStatuses[
                                  negotiationStep.id
                                ]
                                  ? '#e0e0e0'
                                  : '#B0DD28'
                              },
                              'span.chakra-switch__thumb': {
                                backgroundColor: '#fff!important',
                                boxShadow: '0 0 4px 0 rgba(137, 137, 137, 0.25)'
                              }
                            }}
                          />
                          <Text
                            css={V2TextStyles.xs}
                            color={V2Theme.navbar.background}
                            fontWeight={
                              viewStatuses[negotiationStep.id] ? '600' : '400'
                            }
                          >
                            Negotiating
                          </Text>
                        </Flex>
                      )}

                      <Divider />

                      <Box
                        overflow="hidden"
                        maxH={360}
                        overflowY="scroll"
                        px="16px"
                        mx="-16px"
                        pt="32px"
                      >
                        {negotiationStep.terms
                          .filter((term) => {
                            if (!viewStatuses[negotiationStep.id]) {
                              return true;
                            }

                            return (
                              term.status === 'negotiating' ||
                              term.status === 'countered' ||
                              term.status === 'receiver-first' ||
                              term.status === 'non-negotiable-input' ||
                              term.status === 'non-negotiable-answered'
                            );
                          })
                          .map((term, index, array) => (
                            <Flex
                              key={term.fieldId}
                              bg="#FFFFFF"
                              gap="12px"
                              mb={{
                                base: index === array.length - 1 ? '0' : '32px',
                                lg: index === array.length - 1 ? '0' : '32px'
                              }}
                              flexDirection="column"
                              pb={{
                                base: index === array.length - 1 ? '0' : '32px',
                                lg: index === array.length - 1 ? '0' : '32px'
                              }}
                              borderBottom={
                                index === array.length - 1
                                  ? 'none'
                                  : '1px solid #e6e6e6'
                              }
                            >
                              <Flex
                                data-test-id="NegotiationPanelTest"
                                flexDir="column"
                                alignItems="start"
                              >
                                {term.role && (
                                  <Box
                                    data-test-id={`role-${term.role}`}
                                    mb="15px"
                                  >
                                    <Text
                                      css={V2TextStyles.button}
                                      color="#677F1C"
                                      bg="#B0DD284D"
                                      p="4px 8px"
                                      display="inline-block"
                                      rounded="24px"
                                    >
                                      {term.role.toUpperCase()}
                                    </Text>
                                  </Box>
                                )}
                                <Flex
                                  data-test-id={`Question text`}
                                  gap={{ base: '2px', lg: '8px' }}
                                  cursor="pointer"
                                  mb="15px"
                                  flexDir="row"
                                  alignItems={{ base: 'start', lg: 'center' }}
                                  css={V2TextStyles.base}
                                  color="#141414"
                                >
                                  <Flex gap="4px">
                                    <ChartaText
                                      textAlign="left"
                                      answers={panelData.referenceAnswers}
                                    >
                                      {parseQuestionLabel(
                                        term,
                                        parseAnswers(panelData.referenceAnswers)
                                      )}
                                    </ChartaText>
                                  </Flex>

                                  <Flex
                                    alignSelf={{
                                      base: 'start',
                                      lg: 'center'
                                    }}
                                    mt={{ base: '5px', lg: '0' }}
                                    data-info="question-tooltip-icon"
                                    hidden={!term.tooltip}
                                    onClick={() => onShowTooltip(term.tooltip)}
                                  >
                                    <QuestionIconFilled />
                                  </Flex>
                                </Flex>
                              </Flex>

                              {term.status === 'final' ? (
                                <Flex alignItems="center" gap="4px">
                                  <Text
                                    css={V2TextStyles.base}
                                    fontWeight="700"
                                    mt="4px"
                                  >
                                    Final term: {term.value}
                                  </Text>
                                  <Check
                                    fill="#B0DD28"
                                    color="white"
                                    h="13px"
                                    w="13px"
                                  />
                                </Flex>
                              ) : term.status === 'non-negotiable' ? (
                                <Flex gap="8px" alignItems="center">
                                  <Text
                                    color={V2Theme.navbar.background}
                                    css={V2TextStyles.base}
                                  >
                                    {term.value}
                                  </Text>
                                  <Check
                                    fill="#B0DD28"
                                    color="white"
                                    h="13px"
                                    w="13px"
                                  />
                                </Flex>
                              ) : term.status === 'countered' ? (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={V2Theme.navbar.background}
                                        css={V2TextStyles.base}
                                      >
                                        Your new offer: {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                      <ProcessingIcon />
                                    </Flex>
                                    <Flex
                                      alignItems="center"
                                      color="#6F6F6F"
                                      marginTop={'0px'}
                                    >
                                      <Text css={V2TextStyles.xs}>
                                        Their last offer:
                                      </Text>
                                      &nbsp;
                                      <Text css={V2TextStyles.xs}>
                                        {term.prefix}
                                        {term.otherPartyAnswer || '-'}
                                        {term.postfix}
                                      </Text>
                                    </Flex>
                                  </Stack>
                                  <ButtonPrimary
                                    minHeight="45px"
                                    onClick={() => onEditCounterOffer(term)}
                                    hidden={!isUserResponding}
                                    background={
                                      negotiationPanelTheme.negotiationButtonColor
                                    }
                                  >
                                    Edit
                                  </ButtonPrimary>
                                </Flex>
                              ) : term.status === 'negotiating' ? (
                                <Flex
                                  justifyContent="space-between"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <Stack
                                    data-test-id="NegotiatingPanelAnswerStack"
                                    spacing="2px"
                                    justifyContent="space-between"
                                    maxHeight="52px"
                                  >
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        css={V2TextStyles.base}
                                        color={V2Theme.navbar.background}
                                      >
                                        Their offer: {term.prefix}
                                        {term.otherPartyAnswer}
                                        {term.postfix}
                                      </Text>
                                      <ProcessingIcon />
                                    </Flex>
                                    <Flex color="#6F6F6F" margin={0}>
                                      <Text pt="2px" css={V2TextStyles.xs}>
                                        Your last offer:
                                      </Text>
                                      &nbsp;
                                      <Text css={V2TextStyles.xs}>
                                        {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                    </Flex>
                                  </Stack>
                                  {isUserResponding && (
                                    <Flex
                                      gap="16px"
                                      alignItems="start"
                                      justifyContent="end"
                                      flexDir={{
                                        base: 'column',
                                        lg: 'row'
                                      }}
                                      width={{ base: '100%', lg: '70%' }}
                                    >
                                      <FormControl
                                        key={term.fieldId}
                                        isInvalid={!!errors[term.fieldId]}
                                        maxWidth={{ base: '100%', lg: '155px' }}
                                        position="relative"
                                        mt={{ base: '20px', lg: '0' }}
                                        w={{ base: '100%', lg: 'auto' }}
                                      >
                                        <Box position="relative" w="100%">
                                          {term.field.component === 'select' ||
                                          (negotiation &&
                                            term.field.component ===
                                              'radio') ? (
                                            <>
                                              <ChartaField
                                                field={term.field}
                                                register={register}
                                                getValues={getValues}
                                                setValue={setValue}
                                                validations={validations}
                                                control={control}
                                                negotiation={true}
                                                w={{
                                                  base: '100%',
                                                  lg: '155px'
                                                }}
                                                h="45px"
                                                borderRadius={0}
                                                borderColor={
                                                  V2Theme.navbar.background
                                                }
                                                color={
                                                  V2Theme.navbar.background
                                                }
                                                _hover={{
                                                  borderColor:
                                                    V2Theme.navbar.background
                                                }}
                                                css={V2TextStyles.xs}
                                              />
                                            </>
                                          ) : term.field.component ===
                                            'input' ? (
                                            <ChartaField
                                              field={term.field}
                                              register={register}
                                              getValues={getValues}
                                              setValue={setValue}
                                              validations={validations}
                                              control={control}
                                              negotiation={true}
                                              w={{ base: '100%', lg: '155px' }}
                                              h="45px"
                                              borderRadius={0}
                                              borderColor={
                                                V2Theme.navbar.background
                                              }
                                              color={V2Theme.navbar.background}
                                              _hover={{
                                                borderColor: '#B0DD28'
                                              }}
                                              css={V2TextStyles.base}
                                            />
                                          ) : (
                                            <Box>
                                              Unsupported field type:{' '}
                                              {term.field.component}
                                            </Box>
                                          )}
                                        </Box>
                                        <FormErrorMessage>
                                          {errors[term.fieldId] &&
                                            (errors[term.fieldId]
                                              ?.message as string)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <Flex
                                        gap="16px"
                                        flexDirection="row"
                                        justifyContent="end"
                                        w="100%"
                                        maxWidth={{ base: '100%', lg: '326px' }}
                                      >
                                        <ButtonSecondary
                                          minHeight="45px"
                                          maxWidth="155px"
                                          px="42px"
                                          bg="black"
                                          color="white"
                                          width={{ base: '100%' }}
                                          onClick={() =>
                                            onCounterOffer(term.question)
                                          }
                                        >
                                          MAKE COUNTEROFFER
                                        </ButtonSecondary>

                                        <ButtonPrimary
                                          minHeight="45px"
                                          maxWidth="155px"
                                          px="16px"
                                          bg="#D1FD4D"
                                          color="black"
                                          width={{ base: '100%' }}
                                          _hover={{
                                            bg: V2Theme.primaryHover
                                          }}
                                          onClick={() =>
                                            onAcceptOffer(term.question)
                                          }
                                        >
                                          ACCEPT OFFER
                                        </ButtonPrimary>
                                      </Flex>
                                    </Flex>
                                  )}
                                </Flex>
                              ) : term.status === 'receiver-first' ? (
                                <Flex
                                  justifyContent="space-between"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <Stack>
                                    <Flex
                                      gap="8px"
                                      alignItems="center"
                                      data-info="receiver-first-term-icon"
                                    >
                                      <Text css={V2TextStyles.base}>
                                        First to respond
                                      </Text>
                                      <ProcessingIcon />
                                    </Flex>
                                  </Stack>
                                  {isUserResponding && (
                                    <Flex
                                      gap="18px"
                                      alignItems="start"
                                      justifyContent="end"
                                      flexDir={{
                                        base: 'column',
                                        lg: 'row'
                                      }}
                                    >
                                      <FormControl
                                        key={term.fieldId}
                                        isInvalid={!!errors[term.fieldId]}
                                        maxWidth={{ base: '100%', lg: '155px' }}
                                        position="relative"
                                        mt={{ base: '20px', lg: '0' }}
                                        w={{ base: '100%', lg: 'auto' }}
                                      >
                                        <Box position="relative" w="100%">
                                          {term.field.component === 'select' ||
                                          (negotiation &&
                                            term.field.component ===
                                              'radio') ? (
                                            <>
                                              <ChartaField
                                                field={term.field}
                                                register={register}
                                                getValues={getValues}
                                                setValue={setValue}
                                                validations={validations}
                                                control={control}
                                                negotiation={true}
                                                w={{
                                                  base: '100%',
                                                  lg: '155px'
                                                }}
                                                h="45px"
                                                borderRadius={0}
                                                borderColor={
                                                  V2Theme.navbar.background
                                                }
                                                color={
                                                  V2Theme.navbar.background
                                                }
                                                _hover={{
                                                  borderColor:
                                                    V2Theme.navbar.background
                                                }}
                                                css={V2TextStyles.xs}
                                              />
                                            </>
                                          ) : term.field.component ===
                                            'input' ? (
                                            <ChartaField
                                              field={term.field}
                                              register={register}
                                              getValues={getValues}
                                              setValue={setValue}
                                              validations={validations}
                                              control={control}
                                              negotiation={true}
                                              w={{ base: '100%', lg: '155px' }}
                                              h="45px"
                                              borderRadius={0}
                                              borderColor={
                                                V2Theme.navbar.background
                                              }
                                              color={V2Theme.navbar.background}
                                              _hover={{
                                                borderColor: '#B0DD28'
                                              }}
                                              css={V2TextStyles.base}
                                            />
                                          ) : (
                                            <Box>
                                              Unsupported field type:{' '}
                                              {term.field.component}
                                            </Box>
                                          )}
                                        </Box>
                                        <FormErrorMessage>
                                          {errors[term.fieldId] &&
                                            (errors[term.fieldId]
                                              ?.message as string)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <ButtonPrimary
                                        minHeight="45px"
                                        w={{ base: '100%', lg: '155px' }}
                                        _hover={{
                                          bg: V2Theme.primaryHover
                                        }}
                                        onClick={() =>
                                          onCounterOffer(term.question)
                                        }
                                      >
                                        Submit
                                      </ButtonPrimary>
                                    </Flex>
                                  )}
                                </Flex>
                              ) : term.status === 'non-negotiable-input' ? (
                                <Flex
                                  justifyContent="space-between"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <Stack>
                                    <Flex
                                      gap="8px"
                                      alignItems="center"
                                      data-info="non-negotiable-input-term-icon"
                                    >
                                      <Text css={V2TextStyles.base}>
                                        Non-negotiable term
                                      </Text>
                                      <ProcessingIcon />
                                    </Flex>
                                  </Stack>
                                  {isUserResponding && (
                                    <Flex
                                      gap="16px"
                                      alignItems="start"
                                      justifyContent="end"
                                      flexDir={{
                                        base: 'column',
                                        lg: 'row'
                                      }}
                                    >
                                      <FormControl
                                        key={term.fieldId}
                                        isInvalid={!!errors[term.fieldId]}
                                        maxWidth={{ base: '100%', lg: '155px' }}
                                        position="relative"
                                        mt={{ base: '20px', lg: '0' }}
                                        w={{ base: '100%', lg: 'auto' }}
                                      >
                                        <Box position="relative" w="100%">
                                          {term.field.component === 'select' ||
                                          (negotiation &&
                                            term.field.component ===
                                              'radio') ? (
                                            <>
                                              <ChartaField
                                                field={term.field}
                                                register={register}
                                                getValues={getValues}
                                                setValue={setValue}
                                                validations={validations}
                                                control={control}
                                                negotiation={true}
                                                w={{
                                                  base: '100%',
                                                  lg: '155px'
                                                }}
                                                h="45px"
                                                borderRadius={0}
                                                borderColor={
                                                  V2Theme.navbar.background
                                                }
                                                color={
                                                  V2Theme.navbar.background
                                                }
                                                _hover={{
                                                  borderColor:
                                                    V2Theme.navbar.background
                                                }}
                                                css={V2TextStyles.xs}
                                              />
                                            </>
                                          ) : term.field.component ===
                                            'input' ? (
                                            <ChartaField
                                              field={term.field}
                                              register={register}
                                              getValues={getValues}
                                              setValue={setValue}
                                              validations={validations}
                                              control={control}
                                              negotiation={true}
                                              w={{ base: '100%', lg: '155px' }}
                                              h="45px"
                                              borderRadius={0}
                                              borderColor={
                                                V2Theme.navbar.background
                                              }
                                              color={V2Theme.navbar.background}
                                              _hover={{
                                                borderColor: '#B0DD28'
                                              }}
                                              css={V2TextStyles.base}
                                            />
                                          ) : (
                                            <Box>
                                              Unsupported field type:{' '}
                                              {term.field.component}
                                            </Box>
                                          )}
                                        </Box>
                                        <FormErrorMessage>
                                          {errors[term.fieldId] &&
                                            (errors[term.fieldId]
                                              ?.message as string)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <ButtonPrimary
                                        minHeight="45px"
                                        w={{ base: '100%', lg: '155px' }}
                                        _hover={{
                                          bg: V2Theme.primaryHover
                                        }}
                                        onClick={() =>
                                          onCounterOffer(term.question)
                                        }
                                      >
                                        Submit
                                      </ButtonPrimary>
                                    </Flex>
                                  )}
                                </Flex>
                              ) : term.status === 'non-negotiable-answered' ? (
                                <Flex
                                  gap="8px"
                                  alignItems="center"
                                  data-info="non-negotiable-answered-term-icon"
                                >
                                  <Text css={V2TextStyles.base}>
                                    Your answer: {term.value}
                                  </Text>
                                  <ProcessingIcon />
                                </Flex>
                              ) : null}

                              <Flex
                                gap="9px"
                                alignItems="center"
                                alignSelf="start"
                                mt="16px"
                                onClick={() =>
                                  onToggleHistoryStatus(term.question)
                                }
                                _hover={{ cursor: 'pointer' }}
                                display={
                                  [
                                    'receiver-first',
                                    'non-negotiable',
                                    'non-negotiable-input',
                                    'non-negotiable-answered'
                                  ].includes(term.status!)
                                    ? 'none'
                                    : 'flex'
                                }
                                data-info="negotiation-history-toggle"
                              >
                                <Text
                                  css={V2TextStyles.xs}
                                  color={V2Theme.navbar.background}
                                >
                                  Negotiation History
                                </Text>
                                <Box
                                  pt="3px"
                                  sx={
                                    historyStatuses[term.question]
                                      ? {
                                          transform: 'rotate(180deg)'
                                        }
                                      : {}
                                  }
                                >
                                  <ChevronDownSmallIcon />
                                </Box>
                              </Flex>

                              {historyStatuses[term.question] && (
                                <Box>
                                  <Box w="100%">
                                    <Box
                                      display="grid"
                                      w="100%"
                                      gridTemplateColumns="1fr 1fr 1fr"
                                      borderBottom={`1px solid ${tableBorderColor}`}
                                      pb="8px"
                                    >
                                      <Text
                                        css={V2TextStyles.xs}
                                        color="#6F6F6F"
                                      >
                                        Name
                                      </Text>
                                      <Text
                                        css={V2TextStyles.xs}
                                        color="#6F6F6F"
                                        textAlign="center"
                                      >
                                        Value
                                      </Text>
                                      <Text
                                        css={V2TextStyles.xs}
                                        color="#6F6F6F"
                                        textAlign="right"
                                      >
                                        Date
                                      </Text>
                                    </Box>
                                    {getNegotiationTermHistory(
                                      term.question
                                    ).map((history, index) => (
                                      <Box
                                        key={index}
                                        display="grid"
                                        w="100%"
                                        gridTemplateColumns="1fr 1fr 1fr"
                                        py="8px"
                                        borderBottom={`1px solid ${tableBorderColor}`}
                                      >
                                        <Box>
                                          <Flex gap="14px" alignItems="center">
                                            <Avatar
                                              w="20px"
                                              h="20px"
                                              src={history.imageUrl}
                                            />
                                            <Text
                                              css={V2TextStyles.xs}
                                              color={V2Theme.navbar.background}
                                            >
                                              {history.name}
                                            </Text>
                                          </Flex>
                                        </Box>
                                        <Text
                                          textAlign="center"
                                          css={V2TextStyles.xs}
                                          color={V2Theme.navbar.background}
                                        >
                                          {history.value}
                                        </Text>
                                        <Text
                                          textAlign="right"
                                          css={V2TextStyles.xs}
                                          color={V2Theme.navbar.background}
                                        >
                                          {history.date}
                                        </Text>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                            </Flex>
                          ))}
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>

        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="end"
          gap="12px"
        >
          <ButtonSecondary
            w={{ base: '100%', md: '227px' }}
            h="55px"
            disabled={accordionState?.index >= 2}
            onClick={() => {
              setAccordionState((previous) => {
                return {
                  ...previous,
                  index: previous?.index + 1
                };
              });
            }}
          >
            Next
          </ButtonSecondary>
          {isContractSummary ? (
            <ButtonPrimary
              w={{ base: '100%', md: '227px' }}
              height="55px!"
              ml={{ base: '0', md: '14px' }}
              type="button"
              onClick={() => onViewContract()}
              data-heap-id={`${contractTypeName}.view-contract`}
            >
              {(((user?.email === initiator.email &&
              !contractData?.contract?.initiatorSignedAt) ||
              (user?.email === receiver.email &&
                !contractData?.contract?.receiverSignedAt)) &&
              !(user?.email === contractData?.contract?.receiverEmail &&
                (contractType as ContractType).notReceiverSignature)) 
                ? 'Sign Contract' : 'View Contract'}
            </ButtonPrimary>
          ) : (
            isUserResponding && (
              <ButtonSecondary
                w={{ base: '100%', md: '227px' }}
                h="55px"
                ml={{ base: '0', md: '14px' }}
                type="button"
                onClick={() => handleSubmit()}
                isDisabled={termsRemaining > 0}
                _disabled={{
                  opacity: 0.6,
                  cursor: 'not-allowed'
                }}
                data-heap-id={`${contractTypeName}.submit-negotiation`}
                color={V2Theme.navbar.background}
              >
                Submit
              </ButtonSecondary>
            )
          )}
        </Flex>
      </Flex>
      <InfoModal isOpen={tooltipState.isOpen} onClose={onCloseTooltip}>
        <InfoModalHeader>
          <InfoModalTitle>{tooltipState.header}</InfoModalTitle>
        </InfoModalHeader>
        <InfoModalContent>{tooltipState.content}</InfoModalContent>
      </InfoModal>
    </Flex>
  );
};
