import React, { useEffect } from 'react';
import {
  Flex,
  Heading,
  FormControl,
  FormLabel,
  FlexProps,
  useToast,
  Image,
  FormErrorMessage,
  Text
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { loginUser } from '../api';
import { redirectAtom, userAtom } from '../atoms';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from '../v2/FormFields';
import { ButtonForm } from '../v2/Button';

export interface LoginProps extends FlexProps {}

export const Login: React.FC<LoginProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const [user, setUser] = useAtom(userAtom);
  const location = useLocation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({});
  const [redirect] = useAtom(redirectAtom);

  useEffect(() => {
    if (location.state?.newlyRegisteredUser) {
      toast({
        title: 'Registration successful!',
        description: 'Your account was created. Please log in.',
        status: 'success',
        duration: 50000,
        isClosable: true
      });
    }
  }, [location, toast]);

  useEffect(() => {
    if (user) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/');
      }
    }
  }, [user, navigate, redirect]);

  const onSubmit = async (values: any) => {
    try {
      const response = await loginUser(values);
      setUser(response.data);
    } catch {
      toast({
        title: 'Login unsuccessful',
        description: 'Invalid email or password',
        status: 'error',
        duration: 5000
      });
    }
  };

  return (
    <LoginWallLayout>
      <Flex
        as="form"
        noValidate
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        w="100%"
      >
        <Heading color={loginTheme.primaryTextColor} css={V2TextStyles.T1}>
          Login to continue
        </Heading>
        <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
          Don't have an account?{'  '}
          <Link
            to="/register"
            style={{
              paddingLeft: '4px',
              color: loginTheme.primaryTextColor
            }}
          >
            Sign Up
          </Link>
        </Text>

        <FormControl mt="32px" isInvalid={!!errors.email}>
          <FormLabel color={loginTheme.primaryTextColor}>
            Email address
          </FormLabel>
          <Input
            {...register('email', { required: true })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{
              borderColor: loginTheme.inputOutlineHoverColor
            }}
          />
          <FormErrorMessage>Email is required</FormErrorMessage>
        </FormControl>
        <FormControl mt="16px" isInvalid={!!errors.password}>
          <FormLabel color={loginTheme.primaryTextColor}>Password</FormLabel>
          <Input
            type="password"
            {...register('password', { required: true })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{
              borderColor: loginTheme.inputOutlineHoverColor
            }}
          />
          <FormErrorMessage>Password is required</FormErrorMessage>
        </FormControl>

        <Link
          to="/password-reset"
          style={{
            color: loginTheme.primaryTextColor,
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            marginTop: '14px',
            textAlign: 'right'
          }}
        >
          Forgot password?
        </Link>

        <Flex mt="36px" gap="16px">
          <ButtonForm minW={{ lg: '138px' }} h="40px" type="submit" flex="1">
            Login
          </ButtonForm>
        </Flex>
      </Flex>
    </LoginWallLayout>
  );
};

export function LoginWallLayout({ children }: { children: React.ReactNode }) {
  return (
    <Flex minH="100vh">
      <Flex
        w="50%"
        padding="24px"
        alignItems={'center'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        gap={'48px'}
      >
        <Flex w="100%">
          <Link to="/">
            <Image src={`/ChartaLogo-dark.svg`} />
          </Link>
        </Flex>
        <Flex w="100%" justifyContent={'center'} maxW="480px">
          {children}
        </Flex>
        <Flex w="100%">
          <Text lineHeight="100%" css={V2TextStyles.xs}>
            © Charta 2025
          </Text>
        </Flex>
      </Flex>
      <Flex
        h="100%"
        w={'50%'}
        right="0"
        position="fixed"
        top="0"
        padding={'24px'}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          w="100%"
          h="100%"
          backgroundImage={`/login/login-banner.webp`}
          padding={{
            base: '48px',
            lg: '96px'
          }}
          backgroundSize={'cover'}
          backgroundPosition={'center'}
        >
          <Image
            src={`/login/login-lead.svg`}
            style={{
              display: 'block',
              margin: '0px auto',
              position: 'relative'
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
