import { SquareSplitHorizontal } from 'lucide-react';
import { Action, Contracts } from 'src/v2/Contracts';
import { NavBarV2 } from 'src/v2/Navbar';
import { useCompletedContractsQuery } from 'src/v2/data/useQueries';
import { useNavigate } from 'react-router-dom';

export function CompletedContracts() {
  const navigate = useNavigate();
  const completedContracts = useCompletedContractsQuery();
  const actions: Action[] = [
    {
      label: 'Review',
      onClick: (id) => {
        navigate(`/negotiation/${id}`);
      },
      icon: <SquareSplitHorizontal size="16" />
    }
  ];
  return (
    <>
      <NavBarV2 title="Contracts completed" />
      <Contracts
        isLoading={completedContracts.isLoading}
        sections={[
          {
            contracts: completedContracts.data ?? [],
            title: 'Completed',
            actions
          }
        ]}
      />
    </>
  );
}
