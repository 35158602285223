import React, { useCallback, useEffect, useState } from 'react';
import { Flex, FlexProps, useColorModeValue, Box } from '@chakra-ui/react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  navbarColorAtom,
  stepThroughDataAtom,
  stepThroughMetaDataAtom
} from 'src/atoms';
import { useAtom } from 'jotai';
import { ContractSelection } from './ContractSelection';
import { IndustrySelection } from './IndustrySelection';
import { RoleSelection } from './RoleSelection';
import { getContractType } from '../../api';
import { Answer } from 'src/interfaces';

export interface PreStepThroughProps extends FlexProps {
  onClose?: () => void;
}

export const PreStepThrough: React.FC<PreStepThroughProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const [view, setView] = useState<string>('contract');
  const [contractType, setContractType] = useState<any>('');
  const [, setStepThroughData] = useAtom(stepThroughDataAtom);
  const [, setStepThroughMetaData] = useAtom(stepThroughMetaDataAtom);
  const [searchParams] = useSearchParams();

  const goToStepThrough = useCallback(
    (role: string, id?: string, isNewUser = false) => {
      let updatedAnswers: Answer[] = [
        {
          stepId: 'role',
          subStepId: 'role',
          questionId: 'role',
          fieldId: 'role',
          value: role
        }
      ];
      setStepThroughData((data) => ({
        ...data,
        formAnswers: updatedAnswers
      }));

      const url = isNewUser
        ? `/step-through/${id || contractType._id}?status=new`
        : `/step-through/${id || contractType._id}`;
      navigate(url, { replace: true });
    },
    [contractType._id, navigate, setStepThroughData]
  );

  useEffect(() => {
    const contract = searchParams.get('contract');
    const industry = searchParams.get('industry');
    const role = searchParams.get('role');

    const redirect = async () => {
      if (contract !== null && industry !== null && role !== null) {
        const result = await getContractType(contract);
        setContractType(result.data);

        let hasMeta = false;
        let meta: any = {};
        searchParams.forEach((value, key) => {
          if (key !== 'contract' && key !== 'industry' && key !== 'role') {
            hasMeta = true;
            meta[key] = value;
          }
        });

        if (hasMeta) {
          setStepThroughMetaData(meta);
        } else {
          setStepThroughMetaData(null);
        }

        goToStepThrough(role, result.data._id, true);
      }
    };

    redirect();
  }, [goToStepThrough, setContractType, searchParams, setStepThroughMetaData]);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContractSelected = async (id: string) => {
    const result = await getContractType(id);
    setContractType(result.data);
    setView('role');
  };

  const handleIndustrySelected = (id: string) => {
    setView('contract');
  };

  const handleRoleSelected = (role: string) => {
    let updatedAnswers: Answer[] = [
      {
        stepId: 'role',
        subStepId: 'role',
        questionId: 'role',
        fieldId: 'role',
        value: role
      }
    ];
    setStepThroughData((data) => ({
      ...data,
      formAnswers: updatedAnswers
    }));

    navigate(`/step-through/${contractType._id}`, { replace: true });
  };

  return (
    <Flex
      p={{ base: '10px 10px 76px 10px' }}
      alignItems="center"
      flexDirection="column"
      gap="12px"
    >
      {view === 'industry' ? (
        <IndustrySelection onIndustrySelected={handleIndustrySelected} />
      ) : view === 'contract' ? (
        <ContractSelection onContractSelected={handleContractSelected} />
      ) : (
        <RoleSelection
          onRoleSelected={handleRoleSelected}
          roles={contractType.roles}
          isOneSided={contractType.oneSided}
          ignoreCredits={contractType.ignoreCredits}
        />
      )}
    </Flex>
  );
};
