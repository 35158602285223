export function InProgressIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="26" y="1" width="18" height="16" rx="4" fill="#D1FD4D" />
      <path
        d="M31.1801 35.4811C29.9225 36.3235 26.6253 38.0437 28.6335 40.1961C29.6145 41.2475 30.7073 41.9995 32.0809 41.9995H36.0001H39.9193C41.2929 41.9995 42.3857 41.2475 43.3667 40.1961C45.3749 38.0437 42.0777 36.3235 40.8201 35.4811C37.8711 33.5057 34.1291 33.5057 31.1801 35.4811Z"
        fill="#D1FD4D"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M40 24.9995C40 27.2087 38.2092 28.9995 36 28.9995C33.7908 28.9995 32 27.2087 32 24.9995C32 22.7903 33.7908 20.9995 36 20.9995C38.2092 20.9995 40 22.7903 40 24.9995Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M20 11.9995H30M20 5.99951H36"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 18.9995V27.9996C14 29.8852 14 30.828 13.4142 31.4138C12.8284 31.9996 11.8856 31.9996 10 31.9996H8C6.11438 31.9996 5.17158 31.9996 4.58578 31.4138C4 30.828 4 29.8852 4 27.9996V22.9996C4 21.114 4 20.1712 4.58578 19.5853C5.17158 18.9995 6.11438 18.9995 8 18.9995H14ZM14 18.9995H24Z"
        fill="#D1FD4D"
      />
      <path
        d="M14 18.9995V27.9996C14 29.8852 14 30.828 13.4142 31.4138C12.8284 31.9996 11.8856 31.9996 10 31.9996H8C6.11438 31.9996 5.17158 31.9996 4.58578 31.4138C4 30.828 4 29.8852 4 27.9996V22.9996C4 21.114 4 20.1712 4.58578 19.5853C5.17158 18.9995 6.11438 18.9995 8 18.9995H14ZM14 18.9995H24"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 9.99951C13 12.2087 11.2091 13.9995 9 13.9995C6.79086 13.9995 5 12.2087 5 9.99951C5 7.79037 6.79086 5.99951 9 5.99951C11.2091 5.99951 13 7.79037 13 9.99951Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}

export function ReadyToSignIcon() {
  return (
    <svg
      width="48"
      height="52"
      viewBox="0 0 48 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="26" width="24" height="26" rx="4" fill="#D1FD4D" />
      <path
        d="M8 32V16C8 10.3431 8 7.51472 9.75736 5.75736C11.5147 4 14.3431 4 20 4H28.3432C29.1606 4 29.5694 4 29.937 4.15224C30.3044 4.30448 30.5936 4.59352 31.1716 5.17158L38.8284 12.8284C39.4064 13.4065 39.6956 13.6955 39.8478 14.0631C40 14.4306 40 14.8394 40 15.6569V32C40 37.6568 40 40.4852 38.2426 42.2426C36.4852 44 33.6568 44 28 44H20C14.3431 44 11.5147 44 9.75736 42.2426C8 40.4852 8 37.6568 8 32Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 5V8C30 10.8284 30 12.2426 30.8786 13.1213C31.7574 14 33.1716 14 36 14H39"
        fill="#D1FD4D"
      />
      <path
        d="M30 5V8C30 10.8284 30 12.2426 30.8786 13.1213C31.7574 14 33.1716 14 36 14H39"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22H32M16 28H32M16 34H24.3416"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SignedIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.9997 14.0312C43.9997 19.5541 39.5183 24.0313 33.9901 24.0313C28.4619 24.0313 23.9805 19.5541 23.9805 14.0312C23.9805 8.50841 28.4619 4.03125 33.9901 4.03125C39.5183 4.03125 43.9997 8.50841 43.9997 14.0312Z"
        fill="#D1FD4D"
      />
      <path
        d="M29.9609 14.0312C29.9609 14.0312 30.9609 15.0312 31.9609 17.0312C31.9609 17.0312 35.1373 12.0312 37.9609 11.0312"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9897 4.04281C14.9928 3.83125 11.1322 4.40689 11.1322 4.40689C8.69454 4.58119 4.02292 5.94783 4.02296 13.9292C4.023 21.8427 3.97128 31.5987 4.02296 35.4879C4.02296 37.8641 5.4942 43.4067 10.5865 43.7037C16.7762 44.0649 27.9255 44.1417 33.0409 43.7037C34.4103 43.6265 38.9693 42.5515 39.5463 37.5913C40.1441 32.4527 40.0251 28.8815 40.0251 28.0315"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9609 26.0312H21.9609"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.9609 34.0312H29.9609"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function NewContractIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.9997 37.0312C45.9997 42.5541 41.5183 47.0313 35.9901 47.0313C30.4619 47.0313 25.9805 42.5541 25.9805 37.0312C25.9805 31.5084 30.4619 27.0312 35.9901 27.0312C41.5183 27.0312 45.9997 31.5084 45.9997 37.0312Z"
        fill="#D1FD4D"
      />
      <path
        d="M36 33V41"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 37H32"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9426 44H12.3426C9.31297 44 6.85693 41.5299 6.85693 38.4828V9.51724C6.85693 6.47015 9.31297 4 12.3426 4H29.4855L41.1427 15.7241V21.931"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.9999 16.5711H28.5713V5.14258"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ContractWarningIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9898 4.04256C14.9929 3.831 11.1324 4.40664 11.1324 4.40664C8.69466 4.58094 4.02304 5.94758 4.02308 13.929C4.02312 21.8425 3.9714 31.5985 4.02308 35.4877C4.02308 37.8639 5.49432 43.4065 10.5866 43.7035C16.7763 44.0647 27.9256 44.1415 33.041 43.7035C34.4104 43.6263 38.9694 42.5513 39.5464 37.5911C40.1442 32.4525 40.0252 28.8813 40.0252 28.0313"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9611 26.0312H21.9611"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.9611 34.0312H29.9611"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M36 6H32V22H36V6Z" fill="#21201E" />
      <path
        d="M45.7392 12.7662L35.2338 2.25962C34.9059 1.93368 34.4623 1.75073 34 1.75073C33.5377 1.75073 33.0941 1.93368 32.7663 2.25962L22.2663 12.7662C21.9403 13.0941 21.7574 13.5376 21.7574 13.9999C21.7574 14.4623 21.9403 14.9058 22.2663 15.2337L32.7717 25.7402C33.0996 26.0662 33.5432 26.2491 34.0055 26.2491C34.4678 26.2491 34.9113 26.0662 35.2392 25.7402L45.7447 15.2337C46.0706 14.9058 46.2536 14.4623 46.2536 13.9999C46.2536 13.5376 46.0706 13.0941 45.7447 12.7662H45.7392ZM33.125 8.74993C33.125 8.51787 33.2172 8.29531 33.3813 8.13121C33.5454 7.96712 33.7679 7.87493 34 7.87493C34.2321 7.87493 34.4546 7.96712 34.6187 8.13121C34.7828 8.29531 34.875 8.51787 34.875 8.74993V14.8749C34.875 15.107 34.7828 15.3296 34.6187 15.4937C34.4546 15.6577 34.2321 15.7499 34 15.7499C33.7679 15.7499 33.5454 15.6577 33.3813 15.4937C33.2172 15.3296 33.125 15.107 33.125 14.8749V8.74993ZM34 20.1249C33.7404 20.1249 33.4867 20.048 33.2708 19.9037C33.055 19.7595 32.8868 19.5545 32.7874 19.3147C32.6881 19.0749 32.6621 18.811 32.7127 18.5564C32.7634 18.3018 32.8884 18.0679 33.0719 17.8844C33.2555 17.7008 33.4894 17.5758 33.744 17.5252C33.9986 17.4745 34.2625 17.5005 34.5023 17.5998C34.7421 17.6992 34.9471 17.8674 35.0913 18.0832C35.2355 18.2991 35.3125 18.5528 35.3125 18.8124C35.3125 19.1605 35.1742 19.4944 34.9281 19.7405C34.6819 19.9867 34.3481 20.1249 34 20.1249Z"
        fill="#F6C548"
      />
    </svg>
  );
}

export function ReminderIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.00024H10C7.79086 8.00024 6 9.7911 6 12.0002V18.0002M16 8.00024H32M16 8.00024V4.00024M16 8.00024V12.0002M6 18.0002H42V12.0002C42 9.7911 40.2092 8.00024 38 8.00024H32M6 18.0002V40.0002C6 42.2094 7.79086 44.0002 10 44.0002H20M32 8.00024V4.00024M32 8.00024V12.0002"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0192 34C46.0192 39.5228 41.5378 44.0001 36.0096 44.0001C30.4814 44.0001 26 39.5228 26 34C26 28.4772 30.4814 24 36.0096 24C41.5378 24 46.0192 28.4772 46.0192 34Z"
        fill="#D1FD4D"
      />
      <path
        d="M31.9805 34C31.9805 34 32.9805 35 33.9805 37C33.9805 37 37.1569 32 39.9805 31"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRightIcon() {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.5L7 6.5L1 12.5" stroke="black" />
    </svg>
  );
}

export function ChevronDownIcon() {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1L6.5 7L0.5 0.999999"
        stroke="black"
        stroke-opacity="0.4"
      />
    </svg>
  );
}

export function ChevronDownSmallIcon() {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49414 1L3.74718 3.74696L1.00022 1"
        stroke="#141414"
        stroke-opacity="0.4"
        stroke-width="1.21122"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="13.5"
      height="13.5"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3L21 21"
        stroke="#A3A3A3"
        strokeWidth="3"
        strokeLinecap="square"
      />
      <path
        d="M3 21L21 3"
        stroke="#A3A3A3"
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
}

export function DeleteAvatarIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.6999 2.09998C7.39689 2.09998 7.11988 2.27118 6.98436 2.54221L6.40548 3.69998H3.6999C3.25808 3.69998 2.8999 4.05815 2.8999 4.49998C2.8999 4.9418 3.25808 5.29998 3.6999 5.29998V13.3C3.6999 14.1837 4.41625 14.9 5.2999 14.9H11.6999C12.5836 14.9 13.2999 14.1837 13.2999 13.3V5.29998C13.7417 5.29998 14.0999 4.9418 14.0999 4.49998C14.0999 4.05815 13.7417 3.69998 13.2999 3.69998H10.5943L10.0154 2.54221C9.8799 2.27118 9.60294 2.09998 9.2999 2.09998H7.6999ZM6.0999 6.89998C6.0999 6.45815 6.45808 6.09998 6.8999 6.09998C7.34173 6.09998 7.6999 6.45815 7.6999 6.89998V11.7C7.6999 12.1418 7.34173 12.5 6.8999 12.5C6.45808 12.5 6.0999 12.1418 6.0999 11.7V6.89998ZM10.0999 6.09998C9.65806 6.09998 9.2999 6.45815 9.2999 6.89998V11.7C9.2999 12.1418 9.65806 12.5 10.0999 12.5C10.5417 12.5 10.8999 12.1418 10.8999 11.7V6.89998C10.8999 6.45815 10.5417 6.09998 10.0999 6.09998Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export function QuestionIconOutline() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" clip-path="url(#clip0_1_1027)">
        <path
          d="M9 17C4.85775 17 1.5 13.6422 1.5 9.5C1.5 5.35775 4.85775 2 9 2C13.1422 2 16.5 5.35775 16.5 9.5C16.5 13.6422 13.1422 17 9 17ZM9 15.5C10.5913 15.5 12.1174 14.8679 13.2426 13.7426C14.3679 12.6174 15 11.0913 15 9.5C15 7.9087 14.3679 6.38258 13.2426 5.25736C12.1174 4.13214 10.5913 3.5 9 3.5C7.4087 3.5 5.88258 4.13214 4.75736 5.25736C3.63214 6.38258 3 7.9087 3 9.5C3 11.0913 3.63214 12.6174 4.75736 13.7426C5.88258 14.8679 7.4087 15.5 9 15.5ZM8.25 11.75H9.75V13.25H8.25V11.75ZM9.75 10.5162V11H8.25V9.875C8.25 9.67609 8.32902 9.48532 8.46967 9.34467C8.61032 9.20402 8.80109 9.125 9 9.125C9.21306 9.12499 9.42173 9.06447 9.60174 8.9505C9.78175 8.83652 9.9257 8.67377 10.0168 8.48119C10.108 8.2886 10.1425 8.0741 10.1165 7.86263C10.0905 7.65117 10.005 7.45144 9.86987 7.28668C9.73478 7.12193 9.55568 6.99892 9.35342 6.93198C9.15115 6.86503 8.93403 6.8569 8.72732 6.90853C8.52061 6.96016 8.33281 7.06942 8.18577 7.22361C8.03874 7.3778 7.93851 7.57057 7.89675 7.7795L6.42525 7.48475C6.51647 7.02881 6.72713 6.60528 7.03569 6.25744C7.34425 5.9096 7.73964 5.64994 8.18144 5.50499C8.62324 5.36004 9.09561 5.33501 9.55026 5.43246C10.0049 5.52991 10.4255 5.74633 10.7691 6.05962C11.1127 6.3729 11.3669 6.7718 11.5058 7.21555C11.6447 7.65929 11.6633 8.13196 11.5596 8.58523C11.456 9.0385 11.2338 9.45612 10.9159 9.7954C10.5979 10.1347 10.1956 10.3834 9.75 10.5162Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1027">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function QuestionIconFilled() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.000244141"
        width="15"
        height="14.9993"
        rx="7.49966"
        fill="#A9A9A9"
      />
      <path
        d="M6.68307 8.75948C6.68307 7.57206 6.77165 7.438 7.60827 6.84429C8.14961 6.46126 8.32677 6.29847 8.32677 5.89628C8.32677 5.59942 8.14961 5.31215 7.59843 5.31215C6.97835 5.31215 6.67323 5.70476 6.67323 6.42295H5C5 5.08232 5.87598 4.00024 7.64764 4.00024C9.01575 4.00024 10 4.74717 10 5.85797C10 6.62405 9.61614 7.09327 8.92717 7.46673C8.23819 7.84019 7.98228 8.06044 7.98228 8.75948H6.68307ZM6.59449 9.5447H8.09055V11.0002H6.59449V9.5447Z"
        fill="white"
      />
    </svg>
  );
}

export function ExclamationIconFilled() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5C2 6.08172 5.58172 2.5 10 2.5C14.4183 2.5 18 6.08172 18 10.5ZM11 14.5C11 15.0523 10.5523 15.5 10 15.5C9.44772 15.5 9 15.0523 9 14.5C9 13.9477 9.44772 13.5 10 13.5C10.5523 13.5 11 13.9477 11 14.5ZM10 5.5C9.44772 5.5 9 5.94772 9 6.5V10.5C9 11.0523 9.44772 11.5 10 11.5C10.5523 11.5 11 11.0523 11 10.5V6.5C11 5.94772 10.5523 5.5 10 5.5Z"
          fill="#111827"
        />
      </g>
    </svg>
  );
}

export function ProcessingIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5.4" stroke="#F6C548" stroke-width="1.2" />
      <path
        d="M5.99998 2.00037C6.52526 2.00037 7.0454 2.10383 7.5307 2.30485C8.016 2.50586 8.45695 2.8005 8.82839 3.17193C9.19982 3.54336 9.49445 3.98432 9.69547 4.46962C9.89649 4.95492 9.99995 5.47506 9.99995 6.00034C9.99995 6.52563 9.89649 7.04577 9.69547 7.53107C9.49445 8.01637 9.19982 8.45732 8.82838 8.82875C8.45695 9.20018 8.016 9.49482 7.5307 9.69584C7.0454 9.89686 6.52526 10.0003 5.99997 10.0003L5.99998 6.00034V2.00037Z"
        fill="#F6C548"
      />
    </svg>
  );
}

export function ProcessCompletedIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5.4" stroke="#B0DD28" stroke-width="1.2" />
      <circle cx="6.00004" cy="6.00046" r="3.99998" fill="#B0DD28" />
    </svg>
  );
}
