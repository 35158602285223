import React, { useContext } from "react";
import { LoadingContext } from "../providers/loadingProvider";
import { ServerErrorContext } from "../providers/serverErrorProvider";

export const useApiHelpers = () => {
	const { showServerError } = useContext(ServerErrorContext);
	const { showLoading, hideLoading, loading } = useContext(LoadingContext);

	return { showServerError, showLoading, hideLoading, loading };
};
