import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import {
  ContractWarningIcon,
  SignedIcon,
  ReminderIcon
} from '../../v2/DashboardIcons';
import { ResponsiveModal } from './ResponsiveModal';
import { alertModalTheme, V2TextStyles } from 'src/theme/theme';
import { ButtonSecondary } from 'src/v2/Button';
import { CloseIcon } from 'src/v2/DashboardIcons';

export type AlertVariant = 'warning' | 'success' | 'confirmation' | 'reminder';

export interface AlertModalProps {
  variant: AlertVariant;
  acceptText: string;
  isOpen: boolean;
  onAccept: () => void;
  title?: string;
  text?: string;
  text2?: string;
  declineText?: string;
  onDecline?: () => void;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  variant,
  title,
  text,
  text2,
  acceptText,
  declineText,
  onAccept,
  onDecline,
  isOpen,
  ...props
}) => {
  const onClose = onDecline ? onDecline : () => {};

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      maxWidth="560px"
      {...props}
    >
      <Flex
        maxW="560px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="24px"
        mx="auto"
        h={{ base: '326px', md: '394px' }}
        bg={alertModalTheme.background}
        position="relative"
      >
        <Box
          position="absolute"
          top={{ base: '14px', md: '24px' }}
          right={{ base: '14px', md: '24px' }}
          onClick={() => onClose()}
          style={{ cursor: 'pointer' }}
        >
          <CloseIcon />
        </Box>

        {variant === 'warning' ? (
          <ContractWarningIcon />
        ) : variant === 'success' ? (
          <SignedIcon />
        ) : variant === 'confirmation' ? (
          <Box></Box>
        ) : variant === 'reminder' ? (
          <ReminderIcon />
        ) : (
          <Box></Box>
        )}

        <Flex flexDirection="column" alignItems="center" gap="4px" px="14px">
          <Text
            css={V2TextStyles.T1}
            align="center"
            fontWeight="700"
            color={alertModalTheme.headingTextColor}
          >
            {title}
          </Text>
          <Text
            css={V2TextStyles.base}
            align="center"
            color="#141414"
            opacity="0.7"
            maxW="310px"
          >
            {text}
          </Text>
          {text2 != null && (
            <>
              <br />
              <Text align="center" color={alertModalTheme.textColor}>
                {text2}
              </Text>
            </>
          )}
        </Flex>

        <Flex gap="14px" justifyContent="center" mt="10px">
          {declineText && (
            <ButtonSecondary
              onClick={() => onClose()}
              bg="#E8E8E8"
              color="#141414"
              minWidth="106px"
              h={'32px'}
              _hover={{ bg: '#21201E', color: '#D1FD4D' }}
            >
              {declineText}
            </ButtonSecondary>
          )}
          <ButtonSecondary
            minWidth="106px"
            h={'32px'}
            onClick={() => onAccept()}
          >
            {acceptText}
          </ButtonSecondary>
        </Flex>
      </Flex>
    </ResponsiveModal>
  );
};
