import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Container } from '../Container';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { PreStepThrough } from '../../components/pre-step-through/PreStepThrough';

import { ArrowRight, FileIcon, Plus } from 'lucide-react';
import {
  CloseIcon,
  InProgressIcon,
  ReadyToSignIcon,
  SignedIcon
} from '../DashboardIcons';
import { NavBarV2 } from '../Navbar';
import { ButtonPrimary } from '../Button';
import {
  useCompletedContractsCountQuery,
  useContractsReadyCountQuery,
  useNegotiationsInProgressCountQuery
} from '../data/useQueries';

export function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const completedContracts = useCompletedContractsCountQuery();
  const readyToSign = useContractsReadyCountQuery();
  const negotiationsInProgress = useNegotiationsInProgressCountQuery();

  return (
    <>
      <NavBarV2>
        <ButtonPrimary
          role="group"
          textTransform="uppercase"
          _hover={{
            transform: 'scale(1.005) translateY(-3px)',
            shadow: 'md'
          }}
          leftIcon={
            <Box
              display={'flex'}
              w="16px"
              h="16px"
              overflow={'hidden'}
              alignItems={'center'}
              gap={2}
            >
              <Box
                display={'flex'}
                transition="all 0.2s ease-in-out"
                _groupHover={{ transform: 'translateX(-16px)' }}
              >
                <Box
                  transition="all 0.2s ease-in-out"
                  _groupHover={{ opacity: 0 }}
                >
                  <Plus size={16} />
                </Box>
                <Box
                  opacity={0}
                  transition="all 0.2s ease-in-out"
                  _groupHover={{ opacity: 1 }}
                >
                  <FileIcon size={16} />
                </Box>
              </Box>
            </Box>
          }
          onClick={onOpen}
        >
          Start a new contract
        </ButtonPrimary>
      </NavBarV2>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="560px" bg="white" borderRadius="0" shadow="none">
          <Flex justifyContent="flex-end">
            <Box p="22px" style={{ cursor: 'pointer' }} onClick={onClose}>
              <CloseIcon />
            </Box>
          </Flex>
          <PreStepThrough />
        </ModalContent>
      </Modal>

      <Container>
        <Box gap={'32px'} display="flex" flexDirection={'column'} py={'40px'}>
          <HomeSection
            icon={<InProgressIcon />}
            title="Negotiation in progress"
            data={
              negotiationsInProgress.data
                ? [
                    {
                      label: 'Needs a response',
                      value: negotiationsInProgress.data.needsResponseCount
                    },
                    {
                      label: 'Waiting on response',
                      value: negotiationsInProgress.data.waitingOnResponseCount
                    }
                  ]
                : []
            }
            onClick={() => navigate('/dashboard/contracts/offers')}
          />
          <HomeSection
            icon={<ReadyToSignIcon />}
            title="Contracts ready to sign"
            data={
              readyToSign.data
                ? [
                    {
                      label: 'Needs a response',
                      value: readyToSign.data.needsResponseCount
                    },
                    {
                      label: 'Waiting on response',
                      value: readyToSign.data.waitingOnResponseCount
                    }
                  ]
                : []
            }
            onClick={() => navigate('/dashboard/contracts/ready-to-sign')}
          />
          <HomeSection
            icon={<SignedIcon />}
            title="Negotiations"
            data={
              completedContracts.data
                ? [
                    {
                      label: 'Completed',
                      value: completedContracts.data.count
                    }
                  ]
                : []
            }
            onClick={() => navigate('/dashboard/contracts/completed')}
          />
        </Box>
      </Container>
    </>
  );
}

function HomeSection({
  icon,
  title,
  data,
  onClick
}: {
  icon: React.ReactNode;
  title: string;
  data: {
    label: string;
    value: number;
  }[];
  onClick?: () => void;
}) {
  return (
    <Flex
      role="group"
      background={'white'}
      p={'24px'}
      justifyContent="space-between"
      flexDirection={{
        base: 'column',
        md: 'row'
      }}
      alignItems={{
        base: 'flex-start',
        md: 'center'
      }}
      w="full"
      border={1}
      shadow="sm"
      transition="all 0.2s ease-in-out"
      borderColor={'#E9E9E9'}
      _hover={{
        transform: 'scale(1.005) translateY(-3px)',
        shadow: 'md'
      }}
      _active={{
        transform: 'scale(0.995)',
        ringColor: '#DCDCDC'
      }}
      onClick={onClick}
      cursor="pointer"
    >
      <VStack alignItems="flex-start" gap={50} width={'100%'}>
        <Flex alignItems="center" mr={4}>
          {icon}
        </Flex>
        <Text fontSize="xl">{title}</Text>
      </VStack>

      <Flex
        gap={{
          base: '4',
          sm: '12'
        }}
        width={'100%'}
        justifyContent={{
          base: 'flex-start',
          md: 'flex-end'
        }}
        mt={{
          base: '32px',
          md: '0'
        }}
        alignItems={'center'}
      >
        {data.map((item, index) => (
          <Box
            key={item.label}
            mr={index === 0 ? 4 : 0}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Text
              fontSize={{
                base: '32px',
                sm: '40px'
              }}
              display={'flex'}
            >
              {item.value}
            </Text>
            <Text fontSize="sm" display={'flex'} color={'blackAlpha.600'}>
              {item.label}
            </Text>
          </Box>
        ))}

        <Box
          h={{
            base: '40px',
            sm: '60px'
          }}
          w={{
            base: '40px',
            sm: '60px'
          }}
          bg="blackAlpha.50"
          _groupHover={{
            bg: 'black',
            color: 'white'
          }}
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          ml={{
            base: 'auto',
            md: '0'
          }}
          transition="all 0.2s ease-in-out"
          position="relative"
        >
          <ArrowRight size={16} />
        </Box>
      </Flex>
    </Flex>
  );
}
