import {
  Heading,
  FlexProps,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from 'src/v2/FormFields';
import { ButtonForm } from 'src/v2/Button';

export interface EnterPasswordProps extends FlexProps {
  onPasswordEntered: (password: string, confirmPassword: string) => void;
}

export const EnterPassword: React.FC<EnterPasswordProps> = ({
  onPasswordEntered,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});

  const onSubmit = (values: any) => {
    onPasswordEntered(values.password, values.confirmPassword);
  };

  return (
    <Flex
      as="form"
      noValidate
      flexDirection="column"
      w="100%"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
        Reset Password
      </Heading>

      <FormControl mt="32px" isInvalid={!!errors.password}>
        <FormLabel color={loginTheme.primaryTextColor}>New password</FormLabel>
        <Input
          type="password"
          {...register('password', { required: true })}
          borderColor={loginTheme.inputOutlineColor}
          color={loginTheme.inputTypedTextColor}
          _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
        />
        <FormErrorMessage>Password is required</FormErrorMessage>
      </FormControl>

      <FormControl mt="16px" isInvalid={!!errors.confirmPassword}>
        <FormLabel color={loginTheme.primaryTextColor}>
          Confirm password
        </FormLabel>
        <Input
          type="password"
          {...register('confirmPassword', {
            required: true,
            validate: (value: string) => {
              if (watch('password') !== value) {
                return 'Your passwords do not match';
              }
            }
          })}
          borderColor={loginTheme.inputOutlineColor}
          color={loginTheme.inputTypedTextColor}
          _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
        />
        <FormErrorMessage>
          {errors.confirmPassword?.type === 'required'
            ? 'Password confirmation required'
            : 'Passwords do not match'}
        </FormErrorMessage>
      </FormControl>

      <Flex mt="36px" gap="16px">
        <ButtonForm minW={{ lg: '138px' }} h="40px" flex="1" type="submit">
          Submit
        </ButtonForm>
      </Flex>
    </Flex>
  );
};
