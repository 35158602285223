import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ResponsiveModal } from './ResponsiveModal';
import { V2TextStyles } from 'src/theme/theme';
import { CloseIcon, QuestionIconOutline } from 'src/v2/DashboardIcons';

const decodeHTMLEntities = (text: string): string => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
  return textarea.value;
};

const parseContent = (content: string): string[] => {
  return content
    .split('</p>')
    .map((p) => p.replace(/<p>/g, '').trim())
    .filter((p) => p.length > 0)
    .map((p) => decodeHTMLEntities(p));
};

interface InfoModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
}

export function InfoModal({
  children,
  isOpen,
  onClose,
  title,
  content,
  ...props
}: InfoModalProps) {
  const paragraphs = content ? parseContent(content) : [];

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      maxWidth="394px"
      {...props}
    >
      <Flex
        minH="338px"
        flexDirection="column"
        w="100%"
        bg="white"
        position="relative"
        p="24px"
      >
        <Box
          position="absolute"
          top="30px"
          right="24px"
          onClick={onClose}
          cursor="pointer"
        >
          <CloseIcon />
        </Box>
        {children || (
          <>
            <InfoModalHeader>
              <InfoModalTitle>{title}</InfoModalTitle>
            </InfoModalHeader>
            <InfoModalContent>
              {paragraphs.map((paragraph, index) => (
                <InfoModalParagraph key={index}>{paragraph}</InfoModalParagraph>
              ))}
            </InfoModalContent>
          </>
        )}
      </Flex>
    </ResponsiveModal>
  );
}

export function InfoModalHeader({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="start"
      mb="24px"
      w="100%"
    >
      <Flex
        w="100%"
        alignItems="center"
        gap="12px"
        pb="16px"
        mb="24px"
        borderBottom="1px solid #A3A3A3"
      >
        <QuestionIconOutline />
        <Text css={V2TextStyles.base} color="#141414" fontWeight="600">
          Help
        </Text>
      </Flex>
      {children}
    </Flex>
  );
}

export function InfoModalTitle({ children }: { children: React.ReactNode }) {
  return (
    <Box>
      <Text css={V2TextStyles.base} color="#141414" fontWeight="500">
        {children}
      </Text>
    </Box>
  );
}

export function InfoModalContent({ children }: { children: React.ReactNode }) {
  // Si children es un string, aplicamos el parsing
  if (typeof children === 'string') {
    const parsedParagraphs = children
      .split('</p>')
      .map((p) => p.replace(/<p>/g, '').trim())
      .filter((p) => p.length > 0);

    return (
      <Flex flexDirection="column" gap="16px">
        {parsedParagraphs.map((paragraph, index) => (
          <InfoModalParagraph key={index}>{paragraph}</InfoModalParagraph>
        ))}
      </Flex>
    );
  }

  // Si no es string (por ejemplo, ya son componentes React), lo renderizamos directamente
  return (
    <Flex flexDirection="column" gap="16px">
      {children}
    </Flex>
  );
}

export function InfoModalParagraph({
  children
}: {
  children: React.ReactNode;
}) {
  const content =
    typeof children === 'string' ? decodeHTMLEntities(children) : children;

  return (
    <Text w="100%" css={V2TextStyles.xs} color="#141414" opacity="0.7">
      {content}
    </Text>
  );
}
