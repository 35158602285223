import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { alertModalTheme } from 'src/theme/theme';

export interface ResponsiveModalProps {
  title?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  padding?: any;
  minH?: any;
  minW?: any;
  preventFullscreen?: boolean;
  hideCloseButton?: boolean;
  noMarginBody?: boolean;
  noMarginFooter?: boolean;
  maxWidth?: string;
}

export const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  padding = {},
  minH = {},
  minW = {},
  preventFullscreen,
  hideCloseButton = false,
  noMarginBody,
  noMarginFooter,
  maxWidth = '560px'
}) => {
  const bg = alertModalTheme.background;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="1.5px" />
      <ModalContent
        borderRadius="0"
        maxW={maxWidth}
        my="auto"
        mx={{ base: '10px', md: 'auto' }}
        bgColor={bg}
        data-testid="modal-content"
      >
        {!hideCloseButton && (
          <ModalCloseButton
            fontSize={{ base: '17px', md: '18px' }}
            m={{ base: '10px', md: '20px' }}
          />
        )}
        <ModalBody
          data-testid="modal-body"
          w="100%"
          p="0"
          {...(!noMarginBody && { mt: { md: '0' } })}
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
