import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { NavBar } from './components/core/NavBar';
import ScrollToTop from './components/core/ScrollToTop';
import { LoadingProvider } from './providers/loadingProvider';
import { ServerErrorProvider } from './providers/serverErrorProvider';
import theme from './theme/theme';

//Fonts: https://blog.greenroots.info/3-quick-ways-to-add-fonts-to-your-react-app
import '@fontsource/open-sans/400.css';
import './fonts/JustMeAgainDownHere-Regular.ttf';
import './fonts/KolkerBrush-Regular.ttf';
import './fonts/LaBelleAurore-Regular.ttf';
import './fonts/Morguns-Condensed.ttf';
import './fonts/poppins/Poppins-Regular.ttf';
import './fonts/poppins/Poppins-Thin.ttf';
import './fonts/pp-neue-montreal/PPNeueMontreal-Medium.ttf';
import './index.css';
import { DemoVideoProvider } from './providers/demoVideoProvider';

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} resetCSS>
      <Router>
        <ScrollToTop />
        <ServerErrorProvider>
          <LoadingProvider>
            <DemoVideoProvider>
              <App />
            </DemoVideoProvider>
          </LoadingProvider>
        </ServerErrorProvider>
      </Router>
    </ChakraProvider>
  </QueryClientProvider>
);
