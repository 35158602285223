import React, { useEffect, useState } from 'react';
import { Flex, Heading, Box, useDisclosure } from '@chakra-ui/react';
import { AlertModal } from '../core/AlertModal';
import { useNavigate } from 'react-router-dom';
import { getUserCredentials } from 'src/api/payment';
import { V2TextStyles, V2Theme } from 'src/theme/theme';
import { preStepThroughTheme } from 'src/theme/theme';
export interface RoleSelectionProps {
  onRoleSelected: (id: string) => void;
  roles: any[];
  isOneSided: boolean;
  ignoreCredits: boolean;
}

export const RoleSelection: React.FC<RoleSelectionProps> = ({
  onRoleSelected,
  roles,
  isOneSided,
  ignoreCredits
}) => {
  const navigate = useNavigate();
  const [hasEnoughCredits, setHasEnoughCredits] = useState(false);
  const checkCredits = async () => {
    if (!ignoreCredits) {
      const { data } = await getUserCredentials();
      const credits = data.credits;
      if (credits == 0 || credits < 1) {
        onOpen();
        setHasEnoughCredits(false);
      } else {
        setHasEnoughCredits(true);
      }
    } else {
      setHasEnoughCredits(true);
    }
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  useEffect(() => {
    checkCredits().then();
  }, [roles, isOneSided]);
  return (
    <>
      <AlertModal
        isOpen={isOpen}
        onAccept={() => {
          onClose();
          navigate('/dashboard/membership');
        }}
        acceptText="Continue"
        title="You don't have enough credits to send a contract."
        variant="warning"
      />
      <Heading
        textAlign="center"
        mt="6px"
        mb="32px"
        fontSize="24px"
        css={V2TextStyles.T1}
        maxW="288px"
        color={preStepThroughTheme.headingTextColor}
      >
        What's your role for this contract?
      </Heading>
      {roles.map((role, index) => {
        if (!isOneSided || (isOneSided && index === 0)) {
          return (
            <Flex
              key={role.name}
              px="24px"
              py="22px"
              cursor="pointer"
              onClick={() => {
                if (hasEnoughCredits) {
                  onRoleSelected(role.name);
                } else {
                  checkCredits().then();
                }
              }}
              data-heap-id={`role.${role.label}`}
              w={{ base: '100%', lg: '430px' }}
              border="1px solid"
              borderColor="#e9e9e9"
              justifyContent="center"
              alignItems="center"
              color="brand.primary"
              css={V2TextStyles.base}
              _hover={{
                borderColor: V2Theme.primary
              }}
            >
              I'm the {role.label}
            </Flex>
          );
        } else {
          return <Box key={index}></Box>;
        }
      })}
    </>
  );
};
