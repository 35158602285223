import { Route, Routes } from 'react-router-dom';
import { AccountSettings } from 'src/components/dashboard/AccountSettings';
import { Membership } from 'src/components/dashboard/Membership';
import { PaymentHistory } from 'src/components/dashboard/PaymentHistory';

import { CompletedContracts } from 'src/v2/pages/contracts/CompletedContracts';
import { ReadyToSign } from './contracts/ReadyToSign';
import { Offers } from './contracts/Offers';
import { Home } from './Home';

export function DashboardV2() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contracts/offers" element={<Offers />} />
        <Route path="/contracts/ready-to-sign" element={<ReadyToSign />} />
        <Route path="/contracts/completed" element={<CompletedContracts />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="/membership" element={<Membership />} />
      </Routes>
    </>
  );
}
