import { useNavigate } from 'react-router-dom';

import { Bell, EyeOff, SquareSplitHorizontal } from 'lucide-react';
import { Action, Contracts } from '../../Contracts';
import { NavBarV2 } from '../../Navbar';
import {
  useNegotiationsNeedingResponseQuery,
  useNegotiationsWaitingResponseQuery
} from '../../data/useQueries';
import { archiveNegotiation } from 'src/api';

export function Offers() {
  const navigate = useNavigate();
  const needingResponse = useNegotiationsNeedingResponseQuery();
  const waitingResponse = useNegotiationsWaitingResponseQuery();
  const actions: Action[] = [
    {
      label: 'Review',
      onClick: (id) => {
        navigate(`/contracts/${id}`);
      },
      icon: <SquareSplitHorizontal size="16" />
    },
    // { label: 'Remind', onClick: (id) => {}, icon: <Bell size="16" /> },
    {
      label: 'Hide',
      onClick: async (id) => {
        await archiveNegotiation(id);
        needingResponse.refetch();
        waitingResponse.refetch();
      },
      icon: <EyeOff size="16" />
    }
  ];

  return (
    <>
      <NavBarV2 title="Offers" />
      <Contracts
        isLoading={needingResponse.isLoading || waitingResponse.isLoading}
        sections={[
          {
            contracts: needingResponse.data ?? [],
            title: 'Needs a response',
            actions
          },
          {
            contracts: waitingResponse.data ?? [],
            title: 'Waiting on response',
            actions
          }
        ]}
      />
    </>
  );
}
