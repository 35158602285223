import React, { useRef, useState } from 'react';
import {
  type BoxProps,
  chakra,
  HStack,
  Text,
  Menu,
  MenuButton,
  Button,
  VStack
} from '@chakra-ui/react';
import { MenuList, MenuItem, MenuDivider } from './Menu';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { userAtom } from 'src/atoms';
import { getUserProfile, logoutUser } from 'src/api';
import { Logo } from '../components/core/Logo';
import { Container } from './Container';
import { V2TextStyles, V2Theme } from 'src/theme/theme';
import { useQuery } from 'react-query';
import Avatar from 'boring-avatars';
import { HomeIcon, LogOutIcon, SettingsIcon } from 'lucide-react';
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll
} from 'framer-motion';
export interface NavBarProps extends BoxProps {
  title?: string;
  subtitle?: string;
  scrollable?: boolean;
}

export const NavBarV2: React.FC<NavBarProps> = ({
  scrollable = false,
  ...props
}) => {
  const [user, setUser] = useAtom(userAtom);
  const { data: userProfile } = useQuery<{
    profileImageUrl: string;
  } | null>({
    queryFn: async () => {
      const { data } = await getUserProfile();
      return data;
    },
    enabled: !!user
  });

  const navigate = useNavigate();

  const logout = async () => {
    setUser(null);
    try {
      await logoutUser();
    } catch {
    } finally {
      navigate('/login');
    }
  };

  const headerRef = useRef<HTMLDivElement>(null);

  const { scrollY } = useScroll();

  const [scrollState, setScrollState] = useState({
    canDisplay: false,
    isScrollingUp: false
  });
  const previousScrollY = useRef<number>(0);
  useMotionValueEvent(scrollY, 'change', (latest) => {
    setScrollState({
      canDisplay: latest >= 180,
      isScrollingUp: latest < previousScrollY.current
    });
    previousScrollY.current = latest;
  });

  const shouldDisplay =
    scrollState.canDisplay && scrollState.isScrollingUp && scrollable;

  const menu = (
    <Menu>
      <MenuButton
        as={Button}
        color={'white'}
        gap={'12px'}
        bg={'transparent'}
        rightIcon={
          <Avatar
            src={userProfile?.profileImageUrl}
            width={28}
            height={28}
            colors={['#3B98EF', '#223671', '#D1FD4D']}
          />
        }
        paddingRight={0}
      >
        {user?.firstName} {user?.lastName}
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<HomeIcon size={16} />}
          onClick={() => navigate('/dashboard')}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          icon={<SettingsIcon size={16} />}
          onClick={() => navigate('/dashboard/settings')}
        >
          Account Settings
        </MenuItem>
        <MenuDivider />
        <MenuItem icon={<LogOutIcon size={16} />} onClick={logout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <>
      <AnimatePresence>
        {shouldDisplay && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            key="sticky-header"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100
            }}
          >
            <chakra.header
              ref={headerRef}
              py={'24px'}
              bg={V2Theme.navbar.background}
              color="white"
              {...props}
            >
              <Container>
                <HStack spacing="24px" justify="space-between">
                  <Logo />

                  {props.children ?? menu}
                </HStack>
              </Container>
            </chakra.header>
          </motion.div>
        )}
      </AnimatePresence>
      <chakra.header
        ref={headerRef}
        py={'24px'}
        bg={V2Theme.navbar.background}
        color="white"
        {...props}
      >
        <Container>
          <HStack spacing="24px" justify="space-between">
            <Logo />
            {menu}
          </HStack>
          <HStack
            gap={'24px'}
            mt={'50px'}
            h={{
              base: 'auto',
              md: '40px'
            }}
            flexDir={{
              base: 'column',
              sm: 'row'
            }}
            justify="space-between"
            alignItems={{
              base: 'flex-start',
              sm: 'center'
            }}
            spacing={0}
          >
            <VStack align="flex-start" spacing="4px">
              <Text css={V2TextStyles.T1}>
                {props.title ? props.title : `Hello, ${user?.firstName}`}
              </Text>
              {props.subtitle && (
                <Text css={V2TextStyles.base} color="#F8F8F8" opacity="0.6">
                  {props.subtitle}
                </Text>
              )}
            </VStack>
            {props.children}
          </HStack>
        </Container>
      </chakra.header>
    </>
  );
};
