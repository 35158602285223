import {
  Heading,
  FlexProps,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from 'src/v2/FormFields';
import { ButtonForm } from 'src/v2/Button';

export interface EnterEmailProps extends FlexProps {
  onEmailEntered: (email: string) => void;
}

interface FormValues {
  email: string;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({
  onEmailEntered,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    onEmailEntered(data.email);
  };

  return (
    <Flex
      as="form"
      noValidate
      flexDirection="column"
      w="100%"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
        Reset Password
      </Heading>
      <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
        Enter email to receive instructions for resetting your password
      </Text>

      <FormControl mt="32px" isInvalid={!!errors.email}>
        <FormLabel color={loginTheme.primaryTextColor}>Email address</FormLabel>
        <Input
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
          placeholder="Enter your email address"
          borderColor={loginTheme.inputOutlineColor}
          color={loginTheme.inputTypedTextColor}
          _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <Flex mt="36px" gap="16px">
        <ButtonForm type="submit" minW={{ lg: '138px' }} h="40px" flex="1">
          Send code
        </ButtonForm>
      </Flex>
    </Flex>
  );
};
