import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface CheckProps extends IconProps {
  fill?: string; // Color de fondo
  color?: string; // Color del carácter interno
}

export const Check: React.FC<CheckProps> = ({
  fill = 'currentColor',
  color = 'white',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="8" fill={fill} />
      <mask
        id="mask0_757_15781"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" />
      </mask>
      <g mask="url(#mask0_757_15781)">
        <path
          d="M6.58322 11.2492L3.76642 8.4164L4.60002 7.5828L6.58322 9.55L11.4 4.75L12.2336 5.5996L6.58322 11.2492Z"
          fill={color}
        />
      </g>
    </Icon>
  );
};
