import { Text, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { ButtonForm } from 'src/v2/Button';

export interface ResetConfirmationProps {}

export const ResetConfirmation: React.FC<ResetConfirmationProps> = () => {
  const navigate = useNavigate();

  return (
    <Flex flexDirection="column" w="100%">
      <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
        Reset Password
      </Heading>

      <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
        Your password has been reset.
      </Text>

      <Flex mt="36px" gap="16px">
        <ButtonForm
          minW={{ lg: '138px' }}
          h="40px"
          flex="1"
          onClick={() => navigate('/login')}
        >
          Go to login
        </ButtonForm>
      </Flex>
    </Flex>
  );
};
