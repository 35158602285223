import { useQuery } from 'react-query';
import {
  getContractsByStatusNoLimit,
  getContractsCountByStatus,
  getOtherPartyUnfinishedNegotiationsNoLimit,
  getOtherUserUnsignedNegotiations,
  getUserUnfinishedNegotiationsCount,
  getUserUnfinishedNegotiationsNoLimit,
  getUserUnsignedNegotiations,
  getUserUnsignedNegotiationsCount
} from 'src/api';
import { Contract } from '../Contracts';

export function useCompletedContractsCountQuery() {
  return useQuery<{
    count: number;
  }>({
    queryKey: ['completed-contracts-count'],
    queryFn: () =>
      getContractsCountByStatus('both-signed').then((res) => ({
        count: res.data.count
      }))
  });
}

export function useContractsReadyCountQuery() {
  return useQuery<{
    needsResponseCount: number;
    waitingOnResponseCount: number;
  }>({
    queryKey: ['contracts-ready-to-sign-count'],
    queryFn: () =>
      getUserUnsignedNegotiationsCount().then((res) => ({
        needsResponseCount: res.data.count,
        waitingOnResponseCount: res.data.otherCount
      }))
  });
}

export function useNegotiationsInProgressCountQuery() {
  return useQuery<{
    needsResponseCount: number;
    waitingOnResponseCount: number;
  }>({
    queryKey: ['negotiations-in-progress-count'],
    queryFn: () =>
      getUserUnfinishedNegotiationsCount().then((res) => ({
        needsResponseCount: res.data.count,
        waitingOnResponseCount: res.data.otherCount
      }))
  });
}

export function useNegotiationsNeedingResponseQuery() {
  return useQuery<Contract[]>({
    queryKey: ['negotiations-that-needs-response'],
    queryFn: () =>
      getUserUnfinishedNegotiationsNoLimit().then((response) =>
        (response.data || []).map((n: any) => ({
          id: n?._id || 'unknown-id',
          name: n?.subject || 'Untitled Contract',
          date: n?.createdAt ? new Date(n.createdAt).toDateString() : 'No date',
          type: n?.contractType?.name || 'Unknown Type',
          signers: [
            n?.initiator && {
              name:
                `${n.initiator.firstName || ''} ${
                  n.initiator.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.initiator.profileImageUrl
            },
            n?.receiver && {
              name:
                `${n.receiver.firstName || ''} ${
                  n.receiver.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.receiver.profileImageUrl
            }
          ].filter(Boolean),
          status: 'Needs Response'
        }))
      )
  });
}

export function useNegotiationsWaitingResponseQuery() {
  return useQuery<Contract[]>({
    queryKey: ['negotiations-waiting-response'],
    queryFn: () =>
      getOtherPartyUnfinishedNegotiationsNoLimit().then((res) =>
        (res.data || []).map((n: any) => ({
          id: n?._id || 'unknown-id',
          name: n?.subject || 'Untitled Contract',
          date: n?.createdAt ? new Date(n.createdAt).toDateString() : 'No date',
          type: n?.contractType?.name || 'Unknown Type',
          signers: [
            n?.initiator && {
              name:
                `${n.initiator.firstName || ''} ${
                  n.initiator.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.initiator.profileImageUrl
            },
            n?.receiver && {
              name:
                `${n.receiver.firstName || ''} ${
                  n.receiver.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.receiver.profileImageUrl
            }
          ].filter(Boolean),
          status: 'Waiting on Response'
        }))
      )
  });
}

export function useNegotiationsReadyToSignNeedingResponseQuery() {
  return useQuery<Contract[]>({
    queryKey: ['negotiations-ready-to-sign-needing-response'],
    queryFn: () =>
      getUserUnsignedNegotiations().then((res) =>
        (res.data || []).map((n: any) => ({
          id: n?._id || 'unknown-id',
          name: n?.subject || 'Untitled Contract',
          date: n?.createdAt ? new Date(n.createdAt).toDateString() : 'No date',
          type: n?.contractType?.name || 'Unknown Type',
          signers: [
            n?.initiator && {
              name:
                `${n.initiator.firstName || ''} ${
                  n.initiator.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.initiator.profileImageUrl
            },
            n?.receiver && {
              name:
                `${n.receiver.firstName || ''} ${
                  n.receiver.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: n.receiver.profileImageUrl
            }
          ].filter(Boolean),
          status: 'Ready to sign'
        }))
      )
  });
}

export function useNegotiationsReadyToSignWaitingResponseQuery() {
  return useQuery<Contract[]>({
    queryKey: ['negotiations-ready-to-sign-waiting-response'],
    queryFn: () =>
      // TODO(api): replace this with the real API call
      getOtherUserUnsignedNegotiations().then((res) =>
        (res.data || []).map((contract: any) => ({
          id: contract?._id || 'unknown-id',
          name: contract?.subject || 'Untitled Contract',
          date: contract?.createdAt
            ? new Date(contract.createdAt).toDateString()
            : 'No date',
          type: contract?.contractType?.name || 'Unknown Type',
          signers: [
            contract?.initiator && {
              name:
                `${contract.initiator.firstName || ''} ${
                  contract.initiator.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: contract.initiator.profileImageUrl ?? undefined
            },
            contract?.receiver && {
              name:
                `${contract.receiver.firstName || ''} ${
                  contract.receiver.lastName || ''
                }`.trim() || 'Unknown User',
              avatar: contract.receiver.profileImageUrl ?? undefined
            }
          ].filter(Boolean),
          status: 'Ready to sign'
        }))
      )
  });
}

export function useCompletedContractsQuery() {
  return useQuery<Contract[]>({
    queryKey: ['completed-negotiations'],
    queryFn: async () => {
      const res = await getContractsByStatusNoLimit('both-signed');
      return res.data.map((n: any) => ({
        id: n.negotiation._id,
        name: n.negotiation.subject,
        date: new Date(n.negotiation.createdAt).toDateString(),
        type: n.contractType.name,
        signers: [
          {
            name: `${n.initiator.firstName} ${n.initiator.lastName}`,
            avatar: n.initiator.profileImageUrl
          },
          n.receiver && {
            name: `${n.receiver.firstName} ${n.receiver.lastName}`,
            avatar: n.receiver.profileImageUrl
          }
        ].filter(Boolean),
        status: 'Completed'
      })) as Contract[];
    }
  });
}
