import { Box } from '@chakra-ui/react';
import { V2TextStyles } from 'src/theme/theme';

export function Container({ children }: { children: React.ReactNode }) {
  return (
    <Box
      w="full"
      maxW="1080px"
      mx="auto"
      px={'24px'}
      fontFamily={V2TextStyles.base.fontFamily}
    >
      {children}
    </Box>
  );
}
