import React from 'react';
import { useAtom } from 'jotai';
import {
  Box,
  useColorModeValue,
  Flex,
  Text,
  BoxProps,
  Image
} from '@chakra-ui/react';

import { stepThroughDataAtom } from '../../atoms';
import { parseConditions } from '../../utils/parseConditions';
import { parseAnswers } from '../../utils/parseAnswers';
import { ChartaIcon } from '../core/ChartaIcon';
import { stepThroughTheme, V2TextStyles } from 'src/theme/theme';

export interface StepperProps extends BoxProps {}

export const Stepper: React.FC<StepperProps> = ({ ...props }) => {
  const bg = stepThroughTheme.background;
  const highlight = stepThroughTheme.stepperActiveBackgroundColor;
  const activeColor = stepThroughTheme.stepperActiveColor;
  const inactiveColor = stepThroughTheme.stepperInactiveColor;

  const [stepThroughData] = useAtom(stepThroughDataAtom);
  const {
    contractType: contract,
    wizardIndex,
    formAnswers: answers
  } = stepThroughData;
  const {
    form: { steps },
    conditions
  } = contract!;

  return (
    <Box
      pb="68px"
      pr="24px"
      w="224px"
      {...props}
      display={['none', 'none', 'block']}
    >
      <Flex flexDirection="column" w="100%" position="sticky" top="32px">
        {steps.map((step: any) => (
          <Box
            key={step.id}
            mb="48px"
            color={stepThroughTheme.stepperInactiveColor}
          >
            <Flex mb="16px">
              <Text css={V2TextStyles.button} color={'#141414'} opacity={0.6}>
                {step.title}
              </Text>
            </Flex>

            {step.subSteps.map((subStep: any) => {
              const condition = conditions.subSteps.find(
                (c: any) => c[subStep.id]
              );

              const selected =
                steps[wizardIndex.step].subSteps[wizardIndex.subStep].id ===
                subStep.id;

              if (
                !condition ||
                (condition && parseConditions(condition, parseAnswers(answers)))
              ) {
                return (
                  <Flex
                    key={subStep.id}
                    h="48px"
                    alignItems="center"
                    px="24px"
                    mx="-24px"
                    background={selected ? 'rgba(209, 253, 77, 0.2)' : 'none'}
                    position="relative"
                  >
                    {selected ? (
                      <Box
                        position="absolute"
                        left="2"
                        top="0"
                        bottom="0"
                        my="auto"
                        w="4px"
                        h="80%"
                        bg="#B0DD28"
                      ></Box>
                    ) : null}
                    <Text css={V2TextStyles.base}>{subStep.title}</Text>
                  </Flex>
                );
              }

              return <Box key={subStep.id}></Box>;
            })}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
