import React, { useEffect } from 'react';
import {
  Flex,
  Heading,
  FormControl,
  FormLabel,
  FlexProps,
  FormErrorMessage,
  Text
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { verifyUser } from 'src/api';
import { redirectAtom, userAtom, navbarColorAtom } from 'src/atoms';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from '../v2/FormFields';
import { ButtonForm } from '../v2/Button';
import { LoginWallLayout } from './Login';
import { handleDisplayResponseError } from 'src/utils/handleDisplayResponseError';

export interface VerifyProps extends FlexProps {}

export const Verify: React.FC<VerifyProps> = ({ ...props }) => {
  const [user, setUser] = useAtom(userAtom);
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const navigate = useNavigate();
  const [redirect] = useAtom(redirectAtom);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({});

  const onSubmit = async (values: any) => {
    try {
      const response = await verifyUser({ verificationCode: values.code });
      setUser(response.data);
      navigate('/verify-complete');
    } catch (err) {
      handleDisplayResponseError(err);
    }
  };

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user?.role !== 'UnverifiedUser') {
    return <Navigate to={'/'} />;
  }

  return (
    <LoginWallLayout>
      <Flex
        as="form"
        noValidate
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        w="100%"
      >
        <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
          Email Verification
        </Heading>
        <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
          Please enter the verification code that was sent to your email.
        </Text>

        <FormControl mt="32px" isInvalid={!!errors.code}>
          <FormLabel color={loginTheme.primaryTextColor}>
            Verification code
          </FormLabel>
          <Input
            {...register('code', { required: true })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{
              borderColor: loginTheme.inputOutlineHoverColor
            }}
            placeholder="Enter verification code"
          />
          <FormErrorMessage>Verification code is required</FormErrorMessage>
        </FormControl>

        <Flex mt="36px" gap="16px">
          <ButtonForm minW={{ lg: '138px' }} h="40px" type="submit" flex="1">
            Verify
          </ButtonForm>
        </Flex>
      </Flex>
    </LoginWallLayout>
  );
};
