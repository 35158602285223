import {
  Heading,
  FlexProps,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from 'src/v2/FormFields';
import { ButtonForm } from 'src/v2/Button';

export interface EnterCodeProps extends FlexProps {
  onCodeEntered: (code: string) => void;
  onCodeRequested: () => void;
}

interface FormValues {
  code: string;
}

export const EnterCode: React.FC<EnterCodeProps> = ({
  onCodeEntered,
  onCodeRequested,
  ...props
}) => {
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    onCodeEntered(data.code);
  };

  return (
    <Flex
      as="form"
      noValidate
      flexDirection="column"
      w="100%"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
        Reset Password
      </Heading>

      <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
        Enter the code that was sent to your email.
      </Text>

      <FormControl mt="32px" isInvalid={!!errors.code}>
        <FormLabel color={loginTheme.primaryTextColor}>
          Verification code
        </FormLabel>
        <Input
          {...register('code', {
            required: 'Verification code is required',
            minLength: {
              value: 6,
              message: 'Code must be at least 6 characters'
            }
          })}
          placeholder="Enter code"
          borderColor={loginTheme.inputOutlineColor}
          color={loginTheme.inputTypedTextColor}
          _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
        />
        <FormErrorMessage>
          {errors.code && errors.code.message}
        </FormErrorMessage>
      </FormControl>

      <Flex mt="36px" gap="16px">
        <ButtonForm
          type="button"
          variant="outline"
          h="40px"
          flex="1"
          onClick={() => {
            setResendDisabled(true);
            onCodeRequested();
          }}
          color={loginTheme.outlineButtonTextColor}
          borderColor={loginTheme.outlineButtonBorderColor}
          _hover={{ bg: loginTheme.outlineButtonBackgroundColor }}
          disabled={resendDisabled}
        >
          Resend code
        </ButtonForm>
        <ButtonForm type="submit" h="40px" flex="1" minW={{ lg: '138px' }}>
          Verify
        </ButtonForm>
      </Flex>
    </Flex>
  );
};
