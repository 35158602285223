import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { LoadScreen } from '../components/core/LoadScreen';

export interface LoadingContextProps {
  showLoading: () => void;
  hideLoading: () => void;
  loading: boolean;
}

export const LoadingContext = createContext<LoadingContextProps>({
  showLoading: () => {},
  hideLoading: () => {},
  loading: true
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const showLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const hideLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const contextValue = useMemo(
    () => ({ showLoading, hideLoading, loading }),
    [showLoading, hideLoading, loading]
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};
