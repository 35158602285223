import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { ResponsiveModal } from '../components/core/ResponsiveModal';
import { alertModalTheme, V2TextStyles } from 'src/theme/theme';
import { ButtonSecondary } from 'src/v2/Button';
import { CloseIcon } from 'src/v2/DashboardIcons';

// Componente principal
interface AlertModalV2Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function AlertModalV2({
  children,
  isOpen,
  onClose,
  ...props
}: AlertModalV2Props) {
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      maxWidth="560px"
      {...props}
    >
      <Flex
        maxW="560px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="24px"
        mx="auto"
        h={{ base: '326px', md: '394px' }}
        bg={alertModalTheme.background}
        position="relative"
      >
        <Box
          position="absolute"
          top={{ base: '14px', md: '24px' }}
          right={{ base: '14px', md: '24px' }}
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          <CloseIcon />
        </Box>
        {children}
      </Flex>
    </ResponsiveModal>
  );
}

export function AlertModalV2Body({ children }: { children: React.ReactNode }) {
  return (
    <Flex flexDirection="column" alignItems="center" gap="4px" px="14px">
      {children}
    </Flex>
  );
}

export function AlertModalV2Title({
  children,
  ...props
}: {
  children: React.ReactNode;
}) {
  return (
    <Text
      css={V2TextStyles.T1}
      mt="22px"
      align="center"
      fontWeight="700"
      color={alertModalTheme.headingTextColor}
      {...props}
    >
      {children}
    </Text>
  );
}

export function AlertModalV2Content({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <Text
      css={V2TextStyles.base}
      align="center"
      color="#141414"
      opacity="0.7"
      maxW="310px"
    >
      {children}
    </Text>
  );
}

export function AlertModalV2Actions({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex gap="14px" justifyContent="center" mt="10px">
      {children}
    </Flex>
  );
}
