import { Button, type ButtonProps, defineStyle } from '@chakra-ui/react';
import { V2Theme, V2TextStyles } from 'src/theme/theme';

const ButtonPrimaryStyle = defineStyle({
  ...V2TextStyles.button,
  backgroundColor: V2Theme.primary,
  paddingLeft: '20px',
  paddingRight: '20px',
  height: '40px',
  borderRadius: 0,
  paddingTop: 0,
  paddingBottom: 0,
  _hover: {
    backgroundColor: V2Theme.primaryHover
  }
});

export function ButtonPrimary({
  children,
  ...props
}: { children: React.ReactNode } & ButtonProps) {
  return (
    <Button css={ButtonPrimaryStyle} {...props}>
      {children}
    </Button>
  );
}

export function ButtonSecondary({
  children,
  ...props
}: { children: React.ReactNode } & ButtonProps) {
  return (
    <Button
      size="sm"
      color="white"
      borderRadius={0}
      _hover={
        !props.isDisabled
          ? { bg: V2Theme.secondaryHover, color: V2Theme.primary }
          : {}
      }
      css={V2TextStyles.button}
      {...props}
    >
      {children}
    </Button>
  );
}

export function ButtonForm({
  children,
  ...props
}: { children: React.ReactNode } & ButtonProps) {
  return (
    <Button
      size="sm"
      color="white"
      borderRadius={0}
      _hover={{ bg: '#21201E', color: '#D1FD4D' }}
      css={V2TextStyles.button}
      {...props}
    >
      {children}
    </Button>
  );
}
