import React, { useEffect, useState } from 'react';
import { Flex, Box, Heading } from '@chakra-ui/react';
import { getContractTypes } from 'src/api';
import { preStepThroughTheme } from 'src/theme/theme';
import { NewContractIcon, ChevronRightIcon } from 'src/v2/DashboardIcons';
import { V2Theme, V2TextStyles } from 'src/theme/theme';
export interface ContractSelectionProps {
  onContractSelected: (id: string) => void;
}

export const ContractSelection: React.FC<ContractSelectionProps> = ({
  onContractSelected
}) => {
  const [contracts, setContracts] = useState<
    { _id: string; name: string; status: string, order: number }[]
  >([]);

  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getContractTypes();
      console.log(data)
      setContracts(data);
    };

    getContracts();
  }, []);

  return (
    <>
      <NewContractIcon />
      <Heading
        textAlign="center"
        mt="6px"
        mb="32px"
        fontSize="24px"
        css={V2TextStyles.T1}
        maxW="288px"
        color={preStepThroughTheme.headingTextColor}
      >
        What type of contract are you looking for?
      </Heading>
      <Flex
        data-info="contract-selection"
        gap="16px"
        w="100%"
        px="48px"
        flexDirection="column"
      >
        {contracts.map((contract) => (
          <Flex
            key={contract._id}
            px="24px"
            py="22px"
            cursor="pointer"
            onClick={() => {
              if (contract.status === 'Active') {
                onContractSelected(contract._id);
              }
            }}
            data-heap-id={`contract-type.${contract.name}`}
            w={{ base: '100%', lg: '430px' }}
            border="1px solid"
            borderColor="#e9e9e9"
            justifyContent="space-between"
            alignItems="center"
            color="brand.primary"
            css={V2TextStyles.base}
            _hover={{
              borderColor:
                contract.status === 'Active' ? V2Theme.primary : 'none'
            }}
          >
            {contract.name} <ChevronRightIcon />
            <Box
              hidden={contract.status === 'Active'}
              as="span"
              position="absolute"
              marginX="auto"
              bottom="-15px"
              p="2px"
              bg="brand.primary"
              color="brand.secondary1"
              borderRadius="6px"
            >
              Coming Soon
            </Box>
          </Flex>
        ))}
      </Flex>
    </>
  );
};
