import React, { useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useColorModeValue,
  Heading,
  BoxProps,
  Box,
  useDisclosure,
  Text
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { stepThroughDataAtom, userAtom } from '../../atoms';
import { parseConditions } from '../../utils/parseConditions';
import { parseAnswers } from '../../utils/parseAnswers';
import { ChartaText } from '../core/ChartaText';
import { parseQuestionLabel } from '../../utils/parseQuestionLabel';
import { ChartaField } from '../form/ChartaField';

import { createNegotiation, updateNegotiation } from 'src/api';
import { Answer } from 'src/interfaces';
import { sanitizeAnswers } from 'src/utils/sanitizeAnswers';

import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { stepThroughTheme, V2TextStyles } from 'src/theme/theme';
import { ButtonForm, ButtonSecondary } from '../../v2/Button';
import { InfoModal } from '../core/InfoModal';
import {
  AlertModalV2,
  AlertModalV2Title,
  AlertModalV2Content,
  AlertModalV2Body,
  AlertModalV2Actions
} from 'src/v2/AlertModalV2';
import { ContractWarningIcon } from '../../v2/DashboardIcons';
import {
  InfoModalHeader,
  InfoModalTitle,
  InfoModalContent,
  InfoModalParagraph
} from '../core/InfoModal';

export interface WizardProps extends BoxProps {
  onGoBack: () => void;
}

export const Wizard: React.FC<WizardProps> = ({ onGoBack, ...props }) => {
  const bg = stepThroughTheme.background;
  const tooltipColor = useColorModeValue('#6B30BA', 'brand.primary');

  const { showLoading, hideLoading, showServerError } = useApiHelpers();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();
  const [subStep, setSubStep] = useState<any>();
  const [stepThroughData, setStepThroughData] = useAtom(stepThroughDataAtom);
  const {
    contractType: contract,
    wizardIndex,
    formAnswers: answers
  } = stepThroughData;
  const {
    form: { steps },
    conditions,
    validations
  } = contract!;
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting }
  } = useForm();
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });

  watch();

  useEffect(() => {
    //debugger;
    const step = wizardIndex.step;
    const subStep = wizardIndex.subStep;
    setSubStep(steps[step].subSteps[subStep]);
    reset(parseAnswers(answers));
  }, [wizardIndex, answers, reset, steps]);

  const onSubmit = async (values: any) => {
    let answersCopy = [...answers];

    Object.keys(values).forEach((key) => {
      let index = answersCopy.findIndex(
        (answer: Answer) => answer.fieldId === key
      );
      if (index > -1) {
        answersCopy[index].value = values[key];
      } else {
        const step = steps[wizardIndex.step];
        const subStep = steps[wizardIndex.step].subSteps[wizardIndex.subStep];
        const question = subStep.questions.find(
          (question: any) => question.field.id === key
        );

        answersCopy.push({
          fieldId: key,
          stepId: step.id,
          subStepId: subStep.id,
          questionId: question.id,
          value: values[key]
        });
      }
    });

    answersCopy = sanitizeAnswers(answersCopy, conditions);

    let subStepIndex = wizardIndex.subStep + 1;

    for (let i = wizardIndex.step; i < steps.length; i++) {
      for (let j = subStepIndex; j < steps[i].subSteps.length; j++) {
        const subStepId = steps[i].subSteps[j].id;
        const condition = conditions.subSteps.find((c: any) => c[subStepId]);

        if (
          !condition ||
          (condition && parseConditions(condition, parseAnswers(answersCopy)))
        ) {
          const newData = { ...stepThroughData };
          setStepThroughData({
            ...newData,
            formAnswers: answersCopy,
            wizardIndex: {
              step: i,
              subStep: j
            }
          });

          return;
        } else {
          //const subStepId = steps[i].subSteps[j].id;
          answersCopy = answersCopy.filter(
            (answer: Answer) => answer.subStepId !== subStepId
          );
        }
      }
      subStepIndex = 0;
    }

    //TODO: move this to parent component
    let response: any;

    try {
      showLoading();

      if (stepThroughData.negotiation) {
        response = await updateNegotiation(stepThroughData.negotiation, {
          answers: answersCopy
        });
      } else {
        let generateNDAContract = user?.completed == 0 ? true : false;
        response = await createNegotiation({
          contractTypeId: contract!._id,
          answers: answersCopy,
          generateNDAContract,
        });
      }
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
    if (response.data.NDAContractId) {
      if (user) {
        const newUser = user;
        newUser.NDAContractId = response.data.NDAContractId;
        newUser.needOtherResponse++;
        setUser(newUser);
      }
      navigate(`/contract/${response.data.NDAContractId}`);
    } else {
      navigate(`/negotiation/${response.data._id}`, {
        replace: true,
        state: {
          showSentAlert: true
        }
      });
    }
  };

  // const goToNegotiation = () => {
  //   navigate(`/negotiation/${negotiation._id}`, {
  //     replace: true,
  //   });
  // };

  const onBack = () => {
    let answersCopy = [...answers];
    let subStepIndex = wizardIndex.subStep - 1;

    for (let i = wizardIndex.step; i > -1; i--) {
      for (let j = subStepIndex; j > -1; j--) {
        const subStepId = steps[i].subSteps[j].id;
        const condition = conditions.subSteps.find((c: any) => c[subStepId]);

        if (
          !condition ||
          (condition && parseConditions(condition, parseAnswers(answersCopy)))
        ) {
          const newData = { ...stepThroughData };
          setStepThroughData({
            ...newData,
            wizardIndex: {
              step: i,
              subStep: j
            }
          });
          return;
        } else {
          const subStepId = steps[i].subSteps[j].id;
          answersCopy = answersCopy.filter(
            (answer: any) => answer.subStep !== subStepId
          );
        }
      }

      if (i !== 0) {
        subStepIndex = steps[i - 1].subSteps.length - 1;
      }
    }

    onOpen();
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      gap="16px"
      bg={bg}
      py={{ base: '16px', lg: '50px' }}
      px={{ base: '16px', lg: '100px' }}
      w="100%"
      maxW={'700px'}
      ml="auto"
      as="form"
      border="1px solid #E9E9E9"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Flex
        flex="1"
        flexDir="column"
        justifyContent="space-between"
        overflow="auto"
      >
        {subStep && (
          <Flex flexDir="column">
            <Heading
              as="h1"
              css={V2TextStyles.XL}
              color={stepThroughTheme.headingTextColor}
              mb="24px"
            >
              {subStep.title}
            </Heading>

            {subStep.questions
              .filter((question: any) => {
                const condition = conditions.questions?.find(
                  (c: any) => c[question.id]
                );
                if (!condition) {
                  return true;
                } else {
                  return parseConditions(condition, {
                    ...parseAnswers,
                    ...getValues()
                  });
                }
              })
              .map((question: any, index: number) => {
                const pathWithDefaultValue = question.pathWithDefaultValue
                  ? parseConditions(
                      question.pathWithDefaultValue.condition,
                      parseAnswers(answers)
                    )
                  : null;

                const hidden = question.hidden
                  ? parseConditions(question.hidden, parseAnswers(answers))
                  : false;
                const defaultValue = pathWithDefaultValue
                  ? {
                      pathToDefaultValue: question.pathWithDefaultValue.value,
                      objectWithDefaultValue: { user }
                    }
                  : {};
                return (
                  <Box
                    key={question.id}
                    style={{
                      ...(hidden && {
                        display: 'none'
                      })
                    }}
                    mb="8px"
                  >
                    <ChartaText
                      mt={index === 0 ? '16px' : '32px'}
                      mb="12px"
                      css={V2TextStyles.base}
                      color="#141414"
                    >
                      {parseQuestionLabel(question, {
                        ...answers,
                        ...getValues()
                      })}
                    </ChartaText>

                    <Text
                      data-testid="help-button"
                      onClick={() => onShowTooltip(question.tooltip)}
                      color={tooltipColor}
                      fontSize="12px"
                      lineHeight="20px"
                      textDecoration="underline"
                      _hover={{ cursor: 'pointer' }}
                      hidden={!question.tooltip}
                      style={V2TextStyles.base}
                      pb="12px"
                    >
                      {question.tooltip?.label}
                    </Text>

                    <FormControl
                      key={question.field.id}
                      isInvalid={!!errors[question.field.id]}
                    >
                      <ChartaField
                        field={question.field}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        validations={validations}
                        control={control}
                        {...defaultValue}
                        w="100%"
                        color={stepThroughTheme.inputTextColor}
                        borderColor={stepThroughTheme.inputOutlineColor}
                      />
                      <FormErrorMessage>
                        {errors[question.field.id] &&
                          (errors[question.field.id]?.message as string)}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                );
              })}
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="space-evenly" gap="16px" mt="32px">
        <ButtonForm
          bg="#E8E8E8"
          color="#141414"
          h="50px"
          w="100%"
          fontWeight="600px"
          onClick={onBack}
        >
          Back
        </ButtonForm>

        <ButtonForm
          h="50px"
          w="100%"
          type="submit"
          disabled={isSubmitting}
          data-heap-id={`${contract!.name}.${wizardIndex.step}.${
            steps[wizardIndex.step].id
          }.${wizardIndex.subStep}.${
            steps[wizardIndex.step].subSteps[wizardIndex.subStep].id
          }.${answers[0].value}`}
          color={stepThroughTheme.buttonTextColor}
        >
          Next
        </ButtonForm>
      </Flex>
      <InfoModal isOpen={tooltipState.isOpen} onClose={onCloseTooltip}>
        <InfoModalHeader>
          <InfoModalTitle>{tooltipState.header}</InfoModalTitle>
        </InfoModalHeader>
        <InfoModalContent>{tooltipState.content}</InfoModalContent>
      </InfoModal>

      <AlertModalV2 isOpen={isOpen} onClose={onClose}>
        <AlertModalV2Body>
          <ContractWarningIcon />
          <AlertModalV2Title>
            Are you sure you want to start over?
          </AlertModalV2Title>
          <AlertModalV2Content>
            If you leave now, you will lose all your progress.
          </AlertModalV2Content>
        </AlertModalV2Body>
        <AlertModalV2Actions>
          <ButtonSecondary
            onClick={onClose}
            bg="#E8E8E8"
            color="#141414"
            minWidth="106px"
            h={'32px'}
            _hover={{ bg: '#21201E', color: '#D1FD4D' }}
          >
            Cancel
          </ButtonSecondary>
          <ButtonSecondary
            minWidth="106px"
            h={'32px'}
            onClick={() => navigate('/')}
          >
            Continue
          </ButtonSecondary>
        </AlertModalV2Actions>
      </AlertModalV2>
    </Flex>
  );
};
