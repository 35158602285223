import React, { useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  Button
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  getUserProfile,
  saveUserProfileImage,
  updateUserProfile
} from 'src/api';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { accountSettingsTheme, dashboardTheme } from 'src/theme/theme';
import { Input } from 'src/v2/FormFields';
import { ButtonForm } from 'src/v2/Button';
import { DeleteAvatarIcon } from 'src/v2/DashboardIcons';
import { NavBarV2 } from 'src/v2/Navbar';
import { Container } from 'src/v2/Container';

export interface AccountSettingsProps extends BoxProps {}

export const AccountSettings: React.FC<AccountSettingsProps> = ({
  ...props
}) => {
  const { reset, register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const profileImageFile = useRef<HTMLInputElement | null>(null);
  const [newProfileImageBase64, setNewProfileImageBase64] = useState<string>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const { showLoading, showServerError, hideLoading } = useApiHelpers();

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString()?.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

  const onProfileImageSelected = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);
    setProfileImageUrl(imageUrl);
    const base64: string = await toBase64(file);
    setNewProfileImageBase64(base64);
  };
  useEffect(() => {
    const getProfile = async () => {
      const { data } = await getUserProfile();
      setProfileImageUrl(data.profileImageUrl);
      reset(data);
    };

    try {
      showLoading();
      getProfile();
    } catch {
      showServerError();
      hideLoading();
    } finally {
      hideLoading();
    }
  }, [reset]);

  const onSubmit = async (values: any) => {
    values = {
      ...values,
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      professionalName: values?.professionalName?.trim()
    };

    try {
      showLoading();
      if (newProfileImageBase64 != null) {
        await saveUserProfileImage(newProfileImageBase64);
      }
      const { data } = await updateUserProfile(values);
      setProfileImageUrl(data.profileImageUrl);
      reset(data);
    } catch (err) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <NavBarV2 title="Account Settings" />
      <Container>
        <Flex
          mx="auto"
          w="100%"
          bg="white"
          border="1px solid #E9E9E9"
          my="56px"
        >
          <Box
            as="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            p="56px 20px"
            w="100%"
          >
            <Flex
              justifyContent="center"
              flexDirection={{ base: 'column', lg: 'row' }}
              gap={{ base: '40px', lg: '70px' }}
              width="100%"
            >
              <Flex
                flexDir="column"
                width={{ base: '100%', lg: '155px' }}
                alignItems="center"
              >
                <Box
                  position="relative"
                  role="group"
                  _hover={{ cursor: 'pointer' }}
                >
                  <Avatar src={profileImageUrl} size="2xl" />
                  <ButtonForm
                    bg={dashboardTheme.buttonBackgroundColor}
                    p="0"
                    rounded="full"
                    type="button"
                    variant="primary"
                    onClick={() => setProfileImageUrl('')}
                    hidden={!profileImageUrl}
                    position="absolute"
                    top="5px"
                    right="5px"
                    zIndex="1"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    transition="opacity 0.2s"
                  >
                    <DeleteAvatarIcon />
                  </ButtonForm>

                  <input
                    type="file"
                    ref={profileImageFile}
                    style={{ display: 'none' }}
                    onChange={onProfileImageSelected}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </Box>

                <Button
                  bg="transparent"
                  textTransform="capitalize"
                  _hover={{ textDecoration: 'underline' }}
                  fontSize="16px"
                  fontWeight="400"
                  p="0"
                  color={dashboardTheme.buttonBackgroundColor}
                  onClick={() => profileImageFile.current?.click()}
                >
                  Edit
                </Button>
                <Text
                  fontSize="12px"
                  color={dashboardTheme.buttonBackgroundColor}
                  opacity={0.6}
                >
                  Max size: 20mb
                </Text>
              </Flex>

              <Flex
                maxWidth={{ base: '100%', lg: '662px' }}
                flex={{ base: '1', lg: '2' }}
                flexDir="column"
              >
                <Text
                  mb="30px"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="18px"
                  flex="0"
                  width="auto"
                  color={accountSettingsTheme.headingTextColor}
                >
                  Personal Information
                </Text>

                <Flex
                  flexDirection={{ base: 'column', lg: 'row' }}
                  gap={{ base: '30px' }}
                  mb="30px"
                >
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      First name
                    </FormLabel>
                    <Input {...register('firstName')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Last name
                    </FormLabel>
                    <Input {...register('lastName')} />
                  </FormControl>
                </Flex>

                <Flex
                  flexDirection={{ base: 'column', lg: 'row' }}
                  gap={{ base: '30px' }}
                  mb="42px"
                >
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Professional name
                    </FormLabel>
                    <Input {...register('professionalName')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Phone number
                    </FormLabel>
                    <Input {...register('phoneNumber')} />
                  </FormControl>
                </Flex>
                <hr style={{ borderColor: '#E9E9E9' }} />
                <Flex
                  gap="24px"
                  mt="42px"
                  mb="16px"
                  width="100%"
                  alignItems="center"
                >
                  <Text
                    mb="22px"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="18px"
                    flex="0"
                    width="auto"
                    color={accountSettingsTheme.headingTextColor}
                  >
                    Address
                  </Text>
                  <Divider flex="1" />
                </Flex>

                <Flex
                  flexDirection={{ base: 'column', lg: 'row' }}
                  gap={{ base: '30px' }}
                  mb="30px"
                >
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Address line 1
                    </FormLabel>
                    <Input {...register('addressLine1')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Address line 2
                    </FormLabel>
                    <Input {...register('addressLine2')} />
                  </FormControl>
                </Flex>

                <Flex
                  flexDirection={{ base: 'column', lg: 'row' }}
                  gap={{ base: '10px', lg: '30px' }}
                  mb={{ base: '10px', lg: '30px' }}
                >
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      Zip code
                    </FormLabel>
                    <Input {...register('zipCode')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      City
                    </FormLabel>
                    <Input {...register('city')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={accountSettingsTheme.inputTextColor}>
                      State
                    </FormLabel>
                    <Input {...register('state')} />
                  </FormControl>
                </Flex>
                <ButtonForm
                  height={'55px'}
                  maxWidth={{ base: '100%', lg: '200px' }}
                  type="submit"
                >
                  Save changes
                </ButtonForm>
              </Flex>
            </Flex>

            <Heading
              fontSize="40px"
              fontWeight="400"
              lineHeight="48px"
              color={accountSettingsTheme.headingTextColor}
            ></Heading>
          </Box>
        </Flex>
      </Container>
    </>
  );
};
