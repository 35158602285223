import { forwardRef } from 'react';
import {
  Input as InputPrimitive,
  Radio as RadioPrimitive,
  Select as SelectPrimitive,
  InputLeftElement as InputLeftElementPrimitive,
  InputRightElement as InputRightelementPrimitive
} from '@chakra-ui/react';
import { V2TextStyles } from 'src/theme/theme';

export {
  Box,
  RadioGroup,
  InputGroup,
  HStack,
  useColorModeValue,
  Text
} from '@chakra-ui/react';

export const InputRightElement = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof InputRightelementPrimitive>
>((props, ref) => {
  return <InputRightelementPrimitive height="50px" ref={ref} {...props} />;
});

export const InputLeftElement = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof InputLeftElementPrimitive>
>((props, ref) => {
  return <InputLeftElementPrimitive height="50px" ref={ref} {...props} />;
});

export const Select = forwardRef<
  HTMLSelectElement,
  React.ComponentProps<typeof SelectPrimitive>
>((props, ref) => {
  return (
    <SelectPrimitive
      ref={ref}
      {...props}
      rounded="none"
      css={{
        ...V2TextStyles.base,

        '&:focus': {
          borderColor: 'black',
          outline: 'none',
          ring: 'none',
          boxShadow: 'none'
        }
      }}
      _hover={{ borderColor: '#B0DD28' }}
      height={'50px'}
      border="1px solid"
      borderColor="rgba(0,0,0,0.3)"
      outline="none"
    />
  );
});

export const Radio = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof RadioPrimitive>
>((props, ref) => {
  return (
    <RadioPrimitive
      ref={ref}
      {...props}
      // border="none!important"
      border="5px solid #e8e8e8!important"
      background="#FFF!important"
      _checked={{
        background: '#000!important'
      }}
      transition="all 0.2s ease-in-out"
      _hover={{
        '&:not([data-checked])': {
          background: 'rgba(0,0,0,0.3)!important'
        }
      }}
      ring="none"
      outline="none"
      rounded="full"
    />
  );
});

export const Input = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof InputPrimitive>
>((props, ref) => {
  return (
    <InputPrimitive
      {...props}
      rounded="none"
      css={V2TextStyles.base}
      height={'50px'}
      border="1px solid"
      borderColor="rgba(0,0,0,0.3)"
      _hover={{ borderColor: '#000' }}
      _focus={{ borderColor: 'black', outline: 'none', ring: 'none' }}
      outline="none"
      ref={ref}
    />
  );
});
