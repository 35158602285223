import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { StepThrough } from './pages/StepThrough';
import { Negotiation } from './pages/Negotiation';
import { Login } from './pages/Login';

import { Register } from './pages/Register';
import { PasswordReset } from './pages/PasswordReset';
import ProtectedRoute from './components/core/ProtectedRoute';
import { Verify } from './pages/Verify';
import { useAtom } from 'jotai';
import { userAtom } from './atoms';
import { getCurrentUser } from './api';
import { Contract } from './pages/Contract';
import { useApiHelpers } from './hooks/useApiHelpers';
import { ChartaProcess } from './pages/ChartaProcess';
import { PaymentSuccessful } from './pages/tracking/PaymentSuccessful';
import { RegistrationVerified } from './pages/tracking/RegistrationVerified';
import ReactHeap from 'reactjs-heap';
import { LoadScreen } from './components/core/LoadScreen';
import { DashboardV2 } from './v2/pages/Dashboard';
import { Box } from '@chakra-ui/react';
import { V2Theme } from './theme/theme';
import { PreStepThroughV1 } from './pages/PreStepThroughV1';

ReactHeap.initialize(`${process.env.REACT_APP_HEAP_ID}`);

function App() {
  const [user, setUser] = useAtom(userAtom);
  const { hideLoading, loading } = useApiHelpers();

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await getCurrentUser();
        setUser(data);
      } catch (error) {
        setUser(null);
      } finally {
        hideLoading();
      }
    };

    getUser();
  }, [hideLoading, setUser]);

  useEffect(() => {
    if (user) hideLoading();
  }, [user, hideLoading]);

  if (loading) {
    return <LoadScreen />;
  }

  return (
    <Box color="black" bg={V2Theme.mainBackground} minH="100vh">
      <Routes>
        <Route
          path="/step-through/:id"
          element={
            <ProtectedRoute>
              <StepThrough />
            </ProtectedRoute>
          }
        />
        <Route
          path="/step-through"
          element={
            <ProtectedRoute>
              <StepThrough />
            </ProtectedRoute>
          }
        />
        <Route
          path="/negotiation/:id"
          element={
            <ProtectedRoute>
              <Negotiation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contract/:id"
          element={
            <ProtectedRoute>
              <Contract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <DashboardV2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pre-step-through"
          element={
            <ProtectedRoute>
              <PreStepThroughV1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/process"
          element={
            <ProtectedRoute>
              <ChartaProcess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-successful"
          element={
            <ProtectedRoute>
              <PaymentSuccessful />
            </ProtectedRoute>
          }
        />
        <Route
          path="/verify-complete"
          element={
            <ProtectedRoute>
              <RegistrationVerified />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
      </Routes>
    </Box>
  );
}

export default App;
