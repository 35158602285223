import React, { useEffect } from 'react';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Stepper } from '../components/step-through/Stepper';
import { HelpCenter } from '../components/core/HelpCenter';
import { Wizard } from '../components/step-through/Wizard';
import { stepThroughDataAtom, navbarColorAtom } from '../atoms';
import {
  useParams,
  useSearchParams,
  Navigate,
  useNavigate
} from 'react-router-dom';
import { getContractType } from '../api';
import { useApiHelpers } from 'src/hooks/useApiHelpers';

import { NavBarV2 } from 'src/v2/Navbar';
import { Container } from 'src/v2/Container';
import {
  AlertModalV2,
  AlertModalV2Body,
  AlertModalV2Title,
  AlertModalV2Content,
  AlertModalV2Actions
} from '../v2/AlertModalV2';
import { ButtonSecondary } from '../v2/Button';
import { ContractWarningIcon } from '../v2/DashboardIcons';

export const StepThrough = () => {
  const [params] = useSearchParams();
  const negotiation = params.get('negotiation');
  const { showLoading, showServerError, hideLoading } = useApiHelpers();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { id } = useParams();

  const [stepThroughData, setStepThroughData] = useAtom(stepThroughDataAtom);
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { contractType: contract } = stepThroughData;

  useEffect(() => {
    const getContract = async () => {
      let contractType: any;
      let role: any = null;

      if (negotiation) {
        return navigate(`/negotiation/${negotiation}?first-counter=true`);
      } else {
        const result = await getContractType(id!);
        contractType = result.data;
      }

      setStepThroughData((prev) => {
        return {
          contractType,
          formAnswers: negotiation ? [role] : prev.formAnswers,
          wizardIndex: {
            step: 0,
            subStep: 0
          },
          showHelpCenter: false,
          helpCenterTarget: '',
          ...(negotiation ? { negotiation } : {})
        };
      });
    };

    try {
      showLoading();
      getContract();
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
      const status = params.get('status');
      if (status === 'new') {
        onOpen();
      }
    }
  }, [
    id,
    negotiation,
    setStepThroughData,
    showLoading,
    hideLoading,
    showServerError
  ]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    setStepThroughData((data) => ({
      ...data,
      formAnswers: []
    }));

    navigate('/');
  };

  //If the role hasn't been selected - i.e. in the case of a refresh
  if (!negotiation && !stepThroughData.formAnswers.length) {
    return <Navigate to="/" />;
  }

  if (!contract) {
    return <></>;
  }

  return (
    <>
      <NavBarV2 title={contract.name} />
      <Container>
        <AlertModalV2 isOpen={isOpen} onClose={onClose}>
          <AlertModalV2Body>
            <ContractWarningIcon />
            <AlertModalV2Title>NDA required</AlertModalV2Title>
            <AlertModalV2Content>
              You are required to first fill out and sign an NDA before starting
              other contracts.
            </AlertModalV2Content>
          </AlertModalV2Body>
          <AlertModalV2Actions>
            <ButtonSecondary onClick={onClose}>Continue</ButtonSecondary>
          </AlertModalV2Actions>
        </AlertModalV2>
        <Flex mt="50px" pb="50px">
          <Stepper />
          <Wizard flex="1" onGoBack={handleGoBack} />
          <HelpCenter h="100%" overflowY="auto" />
        </Flex>
      </Container>
    </>
  );
};
