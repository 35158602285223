import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Box,
  TableContainer,
  Table,
  Th,
  Td,
  Thead,
  Tr,
  Tbody,
  Badge,
  defineStyle,
  Flex,
  Tooltip,
  Image,
  Skeleton,
  Stack
} from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from './Menu';

import Avatar from 'boring-avatars';

import { Container } from './Container';
import { V2TextStyles } from 'src/theme/theme';
import { useNavigate } from 'react-router-dom';
import { EllipsisIcon } from 'lucide-react';
import { ButtonSecondary } from './Button';

interface Signer {
  name: string;
  avatar?: string; // Optional avatar URL
}

export interface Contract {
  id: string;
  name: string;
  date: string;
  type: string;
  signers: Signer[];
  status: string;
}

export interface Action {
  label: string;
  onClick: (id: string) => void;
  icon: React.ReactElement;
}

interface ContractSection {
  title: string;
  contracts: Contract[];
  actions: Action[];
}

export function Contracts({
  sections: data,
  isLoading
}: {
  sections: ContractSection[];
  isLoading: boolean;
}) {
  return (
    <>
      <Tabs position="relative" variant="unstyled" paddingBottom={'90px'}>
        <Container>
          <TabList h={'90px'}>
            {data.map(({ title, contracts }) => (
              <Tab
                key={title}
                _hover={{
                  color: '#141414'
                }}
                _selected={{
                  color: '#141414',
                  '& .badge': {
                    color: '#141414'
                  }
                }}
                color="#939395"
                css={V2TextStyles.base}
              >
                {title}
                <Badge
                  ml={2}
                  className="badge"
                  css={{
                    ...V2TextStyles.base,
                    background: '#EFEFEF',
                    color: '#939395',
                    padding: '4px 12px',
                    borderRadius: '8px'
                  }}
                >
                  {contracts.length}
                </Badge>
              </Tab>
            ))}
          </TabList>
        </Container>
        <Box borderBottom="1px solid #E9E9E9" />
        <TabIndicator mt="-2px" height="2px" bg="black" borderRadius="1px" />
        <Container>
          <TabPanels>
            {data.map(({ title, contracts, actions }) => {
              if (isLoading) return null;

              return (
                <TabPanel key={title}>
                  <TableContainer>
                    <ContractsTable contracts={contracts} actions={actions} />
                  </TableContainer>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Container>
      </Tabs>
    </>
  );
}

const THStyle = defineStyle({
  ...V2TextStyles.xs,
  color: '#141414',
  textTransform: 'capitalize' as const,
  borderColor: '#E9E9E9',
  paddingBottom: '20px'
});

const TdStyle = defineStyle({
  ...V2TextStyles.xs,
  color: '#141414',
  fontVariantNumeric: 'normal' as const,
  border: 'none',
  paddingTop: '32px',
  paddingBottom: '0px'
});

function ContractsTable({
  contracts,
  actions
}: {
  contracts: Contract[];
  actions: Action[];
}) {
  const navigate = useNavigate();
  if (contracts.length === 0) {
    return (
      <Box
        mt="24px"
        py="80px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="16px"
      >
        <Box color="#939395" css={V2TextStyles.base}>
          There are no contracts or negotiations here yet.
        </Box>
      </Box>
    );
  }

  return (
    <TableContainer mt={'24px'}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th css={THStyle}>Contract</Th>
            <Th css={THStyle}>Date</Th>
            <Th css={THStyle}>Type</Th>
            <Th css={THStyle}>Signers</Th>
            <Th css={THStyle} />
          </Tr>
        </Thead>
        <Tbody>
          {contracts.map((contract, index) => (
            <Tr key={contract.id}>
              <Td css={TdStyle}>{contract.name}</Td>
              <Td css={TdStyle}>{contract.date}</Td>
              <Td css={TdStyle}>{contract.type}</Td>
              <Td css={TdStyle}>
                <Flex marginLeft={'8px'}>
                  {contract.signers.map((signer, index) => (
                    <Tooltip
                      key={`tooltip-${contract.id}-${index}`}
                      label={signer.name}
                      placement="top"
                      bg="black"
                      color="#CACACA"
                      padding={'8px 12px'}
                      css={V2TextStyles.xs}
                    >
                      <Box
                        css={{ marginLeft: '-8px' }}
                        key={`${contract.id}-${index}`}
                        rounded={'full'}
                        border={'2px solid #F8F8F8'}
                      >
                        {signer.avatar ? (
                          <Box
                            w={'28px'}
                            h={'28px'}
                            rounded={'full'}
                            overflow={'hidden'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#EFEFEF'}
                          >
                            <Image
                              objectFit={'cover'}
                              width={'100%'}
                              height={'100%'}
                              src={signer.avatar}
                              alt={signer.name}
                            />
                          </Box>
                        ) : (
                          <Avatar
                            width={28}
                            height={28}
                            colors={['#3B98EF', '#223671', '#D1FD4D']}
                            key={`${contract.id}-${index}`}
                            name={signer.name}
                            src={signer.avatar}
                          />
                        )}
                      </Box>
                    </Tooltip>
                  ))}
                </Flex>
              </Td>
              <Td css={TdStyle} w={'100px'}>
                <Box display={'flex'} gap={'12px'}>
                  <ButtonSecondary
                    onClick={() => {
                      navigate(`/negotiation/${contract.id}`);
                    }}
                  >
                    View negotiation
                  </ButtonSecondary>
                  <Menu>
                    <MenuButton
                      bg={'transparent'}
                      width={'32px'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      _hover={{
                        bg: '#EFEFEF'
                      }}
                      css={{
                        '& span': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                      }}
                    >
                      <EllipsisIcon size={16} />
                    </MenuButton>
                    <MenuList color={'white'}>
                      {actions.map((action) => (
                        <MenuItem
                          key={action.label}
                          icon={action.icon}
                          onClick={() => action.onClick(contract.id)}
                        >
                          {action.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
