import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  BoxProps,
  Image,
  useColorMode
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userAtom } from 'src/atoms';
import { Contract } from 'src/interfaces';
import { contractTheme, V2TextStyles } from 'src/theme/theme';
import { ExclamationIconFilled } from 'src/v2/DashboardIcons';

export interface VieContractProps extends BoxProps {
  contract: Contract;
  lightUrls: string[];
  darkUrls: string[];
}

export const ViewContract: React.FC<VieContractProps> = ({
  contract,
  lightUrls,
  darkUrls,
  ...props
}) => {
  const titleBg = useColorModeValue('#FFFAF3', 'rgba(0, 0, 0, 0.25)');
  const mainBg = contractTheme.contractOutsideBackground;
  const { colorMode } = useColorMode();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    setImageUrls(lightUrls);
    // if (colorMode === 'light') {
    //   setImageUrls(lightUrls);
    // } else {
    //   setImageUrls(darkUrls);
    // }
  }, [colorMode, darkUrls, lightUrls]);

  return (
    <Box
      data-info="contract-view"
      {...props}
      h="100%"
      overflow="hidden"
      borderRadius="5px"
      paddingBottom="10px"
      paddingInline={{ base: '8px 16px', lg: '19px 22px' }}
      w="100%"
    >
      <Flex
        justifyContent="center"
        bg="#fff"
        borderBottom="1px solid #F0F0F1"
        h="92px"
      >
        {((user?.email === contract.initiatorEmail &&
          !contract.initiatorSignedAt) ||
          (user?.email === contract.receiverEmail &&
            !contract.receiverSignedAt)) && (
          <Flex alignItems="center" gap="8px">
            <ExclamationIconFilled />
            <Text css={V2TextStyles.base} textAlign="center">
              Please review your contract carefully before signing it
            </Text>
          </Flex>
        )}
      </Flex>
      <Box bg="#fff" h="100%">
        <Flex
          height="95%"
          overflowY="auto"
          flexDirection="column"
          alignItems="center"
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: '#f3f3f3'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#141414',
              borderRadius: '0px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f3f3f3',
              borderRadius: '0px'
            },

            scrollbarWidth: 'thin',
            scrollbarColor: '#141414 #f3f3f3'
          }}
        >
          {imageUrls.map((url, index) => (
            <Image
              pointerEvents="none"
              src={url}
              alt={`page ${index}`}
              mb="10px"
              key={index}
              w={{ lg: '50%' }}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
