import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image
} from '@chakra-ui/react';
import { ChartaBack } from 'src/components/core/ChartaBack';
import { ViewContract } from 'src/components/contract/ViewContract';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';
import { userAtom } from 'src/atoms';
import { Close } from 'src/components/icons/Close';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getContractById,
  getContractDocx,
  getContractPDF,
  signContract
} from 'src/api';
import { Contract as IContract } from 'src/interfaces';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { contractTheme, V2TextStyles, V2Theme } from 'src/theme/theme';
import { ButtonPrimary } from 'src/v2/Button';
import { ButtonSecondary } from 'src/v2/Button';
import { BackArrow } from 'src/components/icons/BackArrow';
import { Input as FormInput } from 'src/v2/FormFields';

export const Contract = () => {
  const bg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const actionBg = useColorModeValue('brand.secondary1', 'brand.secondary2');

  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFontIndex, setSelectedFontIndex] = useState<number>(0);
  const [user, setUser] = useAtom(userAtom);
  const { showLoading, showServerError, hideLoading } = useApiHelpers();
  const { register, getValues, watch } = useForm({
    defaultValues: {
      fullName: `${user?.firstName} ${user?.lastName}`,
      initials: ''
    }
  });
  const [contractData, setContractData] = useState<{
    contract: IContract;
    urls: { lightUrls: string[]; darkUrls: string[] };
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getContract = async () => {
      const {
        data: { contract, urls }
      } = await getContractById(id!);

      setContractData({
        contract,
        urls
      });
    };

    if (id) {
      try {
        showLoading();
        getContract();
      } catch (error) {
        hideLoading();
        showServerError();
      } finally {
        hideLoading();
      }
    }
  }, [id]);

  watch();

  const fonts = [
    { fontFamily: 'Just Me Again Down Here' },
    { fontFamily: 'Kolker Brush' },
    { fontFamily: 'La Belle Aurore' },
    { fontFamily: 'Open Sans', fontStyle: 'Italic', fontWeight: '700' }
  ];

  const handleOnSign = async () => {
    onClose();
    const darkImage = getBase64ImageString('#FFFFFF');
    const lightImage = getBase64ImageString('#000000');

    try {
      showLoading();
      const {
        data: { contract, urls }
      } = await signContract({
        contractId: contractData!.contract._id,
        lightSignature: lightImage,
        darkSignature: darkImage,
        name: getValues('fullName')
      });

      setContractData({
        contract,
        urls
      });

      if (user) {
        const newUser = user;
        newUser.completed++;
        setUser(newUser);
      }
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
  };

  const getBase64ImageString = (color: string): string => {
    const { fontFamily, fontStyle } = fonts[selectedFontIndex];
    const name = getValues('fullName');

    const canvas = document.createElement('canvas');
    canvas.height = 80;
    canvas.width = name.length * 40;
    const ctx = canvas.getContext('2d');
    if (fontStyle) {
      ctx!.font = `${fontStyle} 48px ${fontFamily}`;
    } else {
      ctx!.font = `48px ${fontFamily}`;
    }
    ctx!.fillStyle = color;
    ctx!.fillText(name, 0, 60);

    return canvas.toDataURL('image/png');
  };

  const downloadPDF = async (negotiationId: string) => {
    const response = await getContractPDF(negotiationId);
    const href = response.data.lightUrls[0];
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'contract.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
  };

  const downloadWordDocument = async (negotiationId: string) => {
    const response = await getContractDocx(negotiationId);
    const href = response.data.lightUrls[0];
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'contract.docx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
  };

  const handleOnBack = async () => {
    navigate('/dashboard');
  };

  if (!contractData) {
    return <LoadScreen />;
  }

  return (
    <>
      <Box
        background={V2Theme.mainBackground}
        h="100vh"
        position="relative"
        overflow="hidden"
        // _before={{
        //   content: "''",
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   width: '100%',
        //   height: '100%',
        //   bgImage: 'url(/a.png)',
        //   bgRepeat: 'no-repeat',
        //   bgPosition: '110% 50%',
        //   bgSize: '50%'
        // }}
      >
        <Flex
          h="100vh"
          overflowY="hidden"
          flexDirection="column"
          maxW="1352px"
          mx="auto"
        >
          <Flex
            position="relative"
            alignItems="center"
            justifyContent="space-between"
            padding={{ base: '8px 16px', lg: '19px 22px' }}
          >
            <ButtonSecondary
              display={{ base: 'none', lg: 'flex' }}
              onClick={() => handleOnBack()}
              color={V2Theme.navbar.background}
              bg="transparent"
              fontSize="12px"
              p="0px"
              _hover={{
                bg: 'transparent',
                '& .icon-container': {
                  bg: V2Theme.navbar.background,
                  borderColor: V2Theme.navbar.background
                },
                '& .back-arrow': {
                  color: 'white'
                }
              }}
            >
              <Box
                className="icon-container"
                as="span"
                mr="8px"
                px="8px"
                pb="7px"
                pt="5px"
                bg="#fff"
                border="1px solid #e9e9e9"
                transition="all 0.2s"
              >
                <BackArrow
                  className="back-arrow"
                  w="8px"
                  transition="all 0.2s ease-in"
                />
              </Box>
              Back
            </ButtonSecondary>
            <Box>
              <Image w="97px" src="/ChartaLogo-dark.svg" />
            </Box>
            <Flex gap="14px" justifyContent={'space-between'}>
              <Menu>
                <MenuButton
                  css={V2TextStyles.button}
                  rounded={'none'}
                  as={Button}
                  color={'white'}
                  _hover={{ bg: '#21201E', color: '#D1FD4D' }}
                >
                  Download
                </MenuButton>
                <MenuList bg={'black'}>
                  {(user?.email === contractData?.contract.initiatorEmail ||
                    user?.email === contractData?.contract.receiverEmail) &&
                    contractData.contract.docxGenerated && <></>}
                  {(user?.email === contractData?.contract.initiatorEmail ||
                    user?.email === contractData?.contract.receiverEmail) && (
                    <MenuItem
                      onClick={() =>
                        downloadPDF(contractData?.contract?.negotiationId)
                      }
                      color={contractTheme.buttonTextColor}
                      bg={'black'}
                    >
                      Download PDF Contract
                    </MenuItem>
                  )}
                  {(user?.email === contractData?.contract.initiatorEmail ||
                    user?.email === contractData?.contract.receiverEmail) &&
                    contractData.contract.docxGenerated && (
                      <MenuItem
                        onClick={() =>
                          downloadWordDocument(
                            contractData?.contract?.negotiationId
                          )
                        }
                        color={contractTheme.buttonTextColor}
                        bg={'black'}
                      >
                        Download Word Contract
                      </MenuItem>
                    )}
                </MenuList>
              </Menu>
              {((user?.email === contractData?.contract.initiatorEmail &&
                !contractData.contract.initiatorSignedAt) ||
                (user?.email === contractData?.contract.receiverEmail &&
                  !contractData.contract.receiverSignedAt)) &&
                !(
                  user?.email === contractData?.contract.receiverEmail &&
                  contractData?.contract.contractType.notReceiverSignature
                ) && (
                  <ButtonPrimary
                    onClick={() => onOpen()}
                    data-heap-id="sign-contract"
                  >
                    Sign PDF Contract
                  </ButtonPrimary>
                )}
            </Flex>
          </Flex>

          <ViewContract
            contract={contractData.contract}
            lightUrls={contractData.urls.lightUrls}
            darkUrls={contractData.urls.darkUrls}
            marginTop={{ base: '8px', lg: '14px' }}
            paddingX={{ base: '8px', lg: '58px' }}
          />
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            maxW={{ base: '100%', lg: '596px' }}
            w="100%"
            position="relative"
            bg="#fff"
            m="16px"
            border="1px solid #E9E9E9"
            rounded="0"
          >
            <Flex>
              <Flex
                padding={{ base: '16px', md: '24px' }}
                gap="18px"
                flex={2}
                flexDir="column"
              >
                <Flex gap="18px" flexDir="column">
                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    pb="20px"
                    borderBottom="1px solid #F0F0F1"
                  >
                    <Text
                      css={V2TextStyles.base}
                      color={V2Theme.navbar.background}
                    >
                      Sign Contract
                    </Text>
                    <Close
                      _hover={{ cursor: 'pointer' }}
                      h="10px"
                      w="10px"
                      color={V2Theme.navbar.background}
                      onClick={onClose}
                    />
                  </Flex>

                  <Flex gap="16px">
                    <FormControl flex={3}>
                      <FormLabel
                        css={V2TextStyles.base}
                        color={V2Theme.navbar.background}
                      >
                        Full name
                      </FormLabel>
                      <FormInput
                        color={V2Theme.navbar.background}
                        data-field-id="text"
                        placeholder="Full name"
                        {...register('fullName', { required: true })}
                      />
                    </FormControl>
                    <FormControl flex={1}>
                      <FormLabel
                        css={V2TextStyles.base}
                        color={V2Theme.navbar.background}
                      >
                        Initials
                      </FormLabel>
                      <FormInput
                        color={V2Theme.navbar.background}
                        data-field-id="text"
                        placeholder="Ex: JL"
                        value={getValues('fullName')
                          ?.split(' ')
                          .map((n) => n[0])
                          .join('')}
                        disabled
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                <Flex flexDir="column" gap="14px">
                  <Text
                    color={V2Theme.navbar.background}
                    css={V2TextStyles.base}
                  >
                    Select style
                  </Text>
                  <Flex flexDir="column" gap="14px">
                    {fonts.map((font: any, key: number) => (
                      <Flex key={key}>
                        <Flex
                          width="100%"
                          minH="128px"
                          padding={{ base: '12px', lg: '16px' }}
                          justifyContent="space-between"
                          alignItems="center"
                          border="1px solid"
                          borderColor={
                            selectedFontIndex === key
                              ? V2Theme.navbar.background
                              : 'rgba(0, 0, 0, 0.3)'
                          }
                          cursor="pointer"
                          onClick={() => setSelectedFontIndex(key)}
                        >
                          <Flex
                            h="100%"
                            flexDir="column"
                            justifyContent="space-between"
                            flex={3}
                          >
                            <Text color="#727272" css={V2TextStyles.xxs}>
                              Signed with Charta
                            </Text>
                            <Text
                              color={V2Theme.navbar.background}
                              fontSize="24px"
                              pb="8px"
                              fontWeight="400"
                              lineHeight="180%"
                              overflow="visible"
                              noOfLines={1}
                              {...font}
                            >
                              {getValues('fullName')}
                            </Text>
                            <Text
                              color={V2Theme.navbar.background}
                              css={V2TextStyles.xs}
                            >
                              9384974349283749802342
                            </Text>
                          </Flex>
                          <Flex
                            flexDir="column"
                            justifyContent="flex-start"
                            h="100%"
                            gap="8px"
                            flex={1}
                          >
                            <Text color="#727272" css={V2TextStyles.xxs}>
                              Initials
                            </Text>
                            <Text
                              color={V2Theme.navbar.background}
                              fontSize="24px"
                              fontWeight="400"
                              lineHeight="36px"
                              h="26px"
                              {...font}
                            >
                              {getValues('fullName')
                                ?.split(' ')
                                .map((n) => n[0])
                                .join('')}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>

                <ButtonSecondary
                  mt="12px"
                  h="55px"
                  w="100%"
                  onClick={() => handleOnSign()}
                >
                  Sign
                </ButtonSecondary>
              </Flex>
            </Flex>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};
