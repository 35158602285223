import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  FlexProps,
  useToast,
  Image,
  FormErrorMessage,
  Text
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { loginUser, registerUser } from '../api';
import { redirectAtom, userAtom } from '../atoms';
import { loginTheme, V2TextStyles } from 'src/theme/theme';
import { Input } from '../v2/FormFields';
import { ButtonForm } from '../v2/Button';
import { LoginWallLayout } from './Login';

export interface RegisterProps extends FlexProps {}

export const Register: React.FC<RegisterProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const [user, setUser] = useAtom(userAtom);
  const [redirect] = useAtom(redirectAtom);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});

  const onSubmit = async (values: any) => {
    try {
      values = {
        ...values,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        email: values?.email?.trim()
      };

      await registerUser(values);
      const response = await loginUser({
        email: values.email,
        password: values.password
      });
      setUser(response.data);
    } catch (err: any) {
      toast({
        title: 'Registration unsuccessful',
        description: err?.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/');
      }
    }
  }, [user, navigate, redirect]);

  return (
    <LoginWallLayout>
      <Flex
        as="form"
        noValidate
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        w="100%"
      >
        <Heading css={V2TextStyles.T1} color={loginTheme.primaryTextColor}>
          Create an account
        </Heading>
        <Text mt="6px" css={V2TextStyles.base} color="#8a8a8a">
          Already have an account?{' '}
          <Link
            to="/login"
            style={{
              color: loginTheme.primaryTextColor,
              paddingLeft: '4px'
            }}
          >
            Login
          </Link>
        </Text>

        <Flex gap="16px" mt="32px" flexDir={{ base: 'column', lg: 'row' }}>
          <FormControl isInvalid={!!errors.firstName} flex="1">
            <FormLabel color={loginTheme.primaryTextColor}>
              First name
            </FormLabel>
            <Input {...register('firstName', { required: true })} />
            <FormErrorMessage>First name is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.lastName} flex="1">
            <FormLabel color={loginTheme.primaryTextColor}>Last name</FormLabel>
            <Input {...register('lastName', { required: true })} />
            <FormErrorMessage>Last name is required</FormErrorMessage>
          </FormControl>
        </Flex>

        <FormControl mt="16px" isInvalid={!!errors.email}>
          <FormLabel color={loginTheme.primaryTextColor}>
            Email address
          </FormLabel>
          <Input
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
          />
          <FormErrorMessage>
            {errors.email?.type === 'required'
              ? 'Email is required'
              : 'Invalid email format'}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt="16px" isInvalid={!!errors.password}>
          <FormLabel color={loginTheme.primaryTextColor}>Password</FormLabel>
          <Input
            type="password"
            {...register('password', { required: true })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
          />
          <FormErrorMessage>Password is required</FormErrorMessage>
        </FormControl>

        <FormControl mt="16px" isInvalid={!!errors.confirmPassword}>
          <FormLabel color={loginTheme.primaryTextColor}>
            Confirm password
          </FormLabel>
          <Input
            type="password"
            {...register('confirmPassword', {
              required: true,
              validate: (value: string) => {
                if (watch('password') !== value) {
                  return 'Your passwords do not match';
                }
              }
            })}
            borderColor={loginTheme.inputOutlineColor}
            color={loginTheme.inputTypedTextColor}
            _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
          />
          <FormErrorMessage>
            {errors.confirmPassword?.type === 'required'
              ? 'Password confirmation required'
              : 'Passwords do not match'}
          </FormErrorMessage>
        </FormControl>

        <Flex mt="36px" gap="16px">
          <ButtonForm minW={{ lg: '138px' }} h="40px" type="submit" flex="1">
            Sign Up
          </ButtonForm>
        </Flex>
      </Flex>
    </LoginWallLayout>
  );
};
