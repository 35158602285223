import { Bell, EyeOff, SquareSplitHorizontal } from 'lucide-react';
import { Action, Contracts } from '../../Contracts';
import { NavBarV2 } from '../../Navbar';
import {
  useNegotiationsReadyToSignNeedingResponseQuery,
  useNegotiationsReadyToSignWaitingResponseQuery
} from '../../data/useQueries';
import { useNavigate } from 'react-router-dom';
import { archiveNegotiation } from 'src/api/negotiation';

export function ReadyToSign() {
  const navigate = useNavigate();
  const needingResponse = useNegotiationsReadyToSignNeedingResponseQuery();
  const waitingResponse = useNegotiationsReadyToSignWaitingResponseQuery();
  const actions: Action[] = [
    {
      label: 'Review',
      onClick: (id) => {
        navigate(`/negotiation/${id}`);
      },
      icon: <SquareSplitHorizontal size="16" />
    },
    // { label: 'Remind', onClick: (id) => {}, icon: <Bell size="16" /> },
    {
      label: 'Hide',
      onClick: async (id) => {
        await archiveNegotiation(id);
        needingResponse.refetch();
        waitingResponse.refetch();
      },
      icon: <EyeOff size="16" />
    }
  ];
  return (
    <>
      <NavBarV2 title="Contracts ready to sign" />
      <Contracts
        isLoading={needingResponse.isLoading || waitingResponse.isLoading}
        sections={[
          {
            contracts: needingResponse.data ?? [],
            title: 'Needs a response',
            actions
          },
          {
            contracts: waitingResponse.data ?? [],
            title: 'Waiting on response',
            actions
          }
        ]}
      />
    </>
  );
}
