import React, { useEffect, useState } from 'react';
import { Flex, FlexProps, useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from 'src/atoms';
import { useNavigate } from 'react-router-dom';
import { EnterEmail } from '../components/password-reset/EnterEmail';
import { EnterCode } from 'src/components/password-reset/EnterCode';
import { EnterPassword } from 'src/components/password-reset/EnterPassword';
import { ResetConfirmation } from 'src/components/password-reset/ResetConfirmation';
import {
  completePasswordReset,
  startPasswordReset,
  verifyPassword
} from 'src/api';
import { LoginWallLayout } from './Login';

export interface PasswordResetProps extends FlexProps {}
type ResetPasswordView = 'email' | 'code' | 'password' | 'confirmation';

export const PasswordReset: React.FC<PasswordResetProps> = ({ ...props }) => {
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const toast = useToast();

  const [resetState, setResetState] = useState<{
    view: ResetPasswordView;
    email: string;
    resetCode: string;
    password: string;
    confirmPassword: string;
    _id: string;
  }>({
    view: 'email',
    email: '',
    resetCode: '',
    password: '',
    confirmPassword: '',
    _id: ''
  });

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleEmailEntered = async (email: string) => {
    try {
      await startPasswordReset({ email });
      setResetState({
        view: 'code',
        email,
        resetCode: '',
        password: '',
        confirmPassword: '',
        _id: ''
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000
      });
    }
  };

  const handleCodeRequested = async () => {
    try {
      await startPasswordReset({ email: resetState.email });
      toast({
        title: 'Code Sent',
        description: 'A new code has been sent to your email',
        status: 'success',
        duration: 5000
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000
      });
    }
  };

  const handleCodeEntered = async (resetCode: string) => {
    try {
      const {
        data: { _id }
      } = await verifyPassword({ resetCode });
      setResetState((prev) => ({
        ...prev,
        resetCode,
        view: 'password',
        _id
      }));
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message || 'Invalid code',
        status: 'error',
        duration: 5000
      });
    }
  };

  const handlePasswordEntered = async (
    password: string,
    confirmPassword: string
  ) => {
    try {
      await completePasswordReset({
        ...resetState,
        password,
        confirmPassword
      });
      setResetState((prev) => ({
        ...prev,
        view: 'confirmation'
      }));
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000
      });
    }
  };

  return (
    <LoginWallLayout>
      <Flex w="100%" {...props}>
        {resetState.view === 'email' ? (
          <EnterEmail onEmailEntered={handleEmailEntered} />
        ) : resetState.view === 'code' ? (
          <EnterCode
            onCodeEntered={handleCodeEntered}
            onCodeRequested={handleCodeRequested}
          />
        ) : resetState.view === 'password' ? (
          <EnterPassword onPasswordEntered={handlePasswordEntered} />
        ) : (
          <ResetConfirmation />
        )}
      </Flex>
    </LoginWallLayout>
  );
};
