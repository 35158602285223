import { defineStyle, extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';
import { accordionTheme } from './accordion';
import { buttonTheme } from './button';
import { radioTheme } from './radio';
import { switchTheme } from './switch';

const theme = extendTheme(
  {
    config: {
      useSystemColorMode: false,
      initialColorMode: 'dark'
    },
    colors: {
      brand: {
        primary: '#000000',
        secondary1: '#000000',
        secondary2: '#040518',
        secondary3: '#E2E8F0'
      }
    },
    fonts: {
      body: 'Poppins, sans-serif',
      heading: 'Poppins-Thin, sans-serif'
    },
    components: {
      Radio: radioTheme,
      Accordion: accordionTheme,
      Switch: switchTheme,
      Button: buttonTheme
    }
  },
  withProse({
    baseStyle: {
      p: {
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '0'
      },
      em: {
        fontStyle: 'italic'
      },
      b: {
        fontWeight: '700'
      }
    }
  })
);
export const universalTheme = {
  background: 'white',
  mainContentFixedHeight: '100vh',
  sideNavBackgroundColor: ''
};
export const navbarTheme = {
  background: '#141414'
};
export const footerTheme = {
  background: '#000000'
};

export const loginTheme = {
  mainContentFixedHeight: universalTheme.mainContentFixedHeight,
  primaryTextColor: '#000000',
  buttonBackgroundColor: '#000000',
  buttonTextColor: 'white',
  outlineButtonBackgroundColor: 'transparent',
  outlineButtonTextColor: '#000000',
  outlineButtonBorderColor: '#000000',
  background: universalTheme.background,
  inputOutlineColor: '#CBD5E0',
  inputOutlineHoverColor: '#000000',
  inputTypedTextColor: 'black'
};

export const passwordResetTheme = {
  background: universalTheme.background,
  modalBackground: universalTheme.background,
  modalHeadingColor: '#000000',
  modalBodyColor: 'black',
  inputColor: '#000000',
  inputOutlineHoverColor: '#000000'
};

export const dashboardTheme = {
  primaryTextColor: '#000000',
  background: universalTheme.background,
  containerBackground: universalTheme.background,
  buttonBackgroundColor: '#000000',
  buttonTextColor: 'white',
  outlineButtonTextColor: 'black',
  receivedContractBoxBorderColor: 'black',
  receivedContractBoxBackground: '#FFFFFF',
  receivedContractContractTypeTextColor: 'black',
  receivedContractOfferOutlineButtonText: 'black',
  contractTypePillBackground: '#C4C4C4',
  contractTypePillTextColor: 'black',
  createContractIconColor: 'blackrgba(27, 28, 49, 0.5)',
  termsRemainingColor: '#FFC077',
  sentOfferHeadingBackground: 'black',
  sentOfferHeadingColor: 'white',
  sentOfferBackground: '#AFAFAF',
  sentOfferContractTypeTextColor: 'white',
  sentOfferButtonBackground: 'black',
  sentOfferButtonTextColor: 'white',
  sentOfferOutlineButtonText: 'white',
  sentOfferOutlineBackgroundColor: 'white',
  completedContractsBackground: 'white',
  completedContractContractTypeTextColor: 'black',
  completedContractOutlineButtonText: 'white',
  viewAllColor: 'white',
  viewAllBackgronudColor: 'white',
  sideNavSelectedTabBackgroundColor: 'white',
  sideNavSelectedColor: 'black',
  sideNavBackgroundColor: '#000000',
  hamburgerMenuIconColor: '#000000'
};

export const accountSettingsTheme = {
  headingTextColor: '#000000',
  inputTextColor: '#000000',
  outlineTextColor: '#000000',
  saveButtonTextColor: 'white',
  inputOutlineColor: '#000000',
  outlineButtonBorderColor: '#000000'
};

export const chartaProcessTheme = {
  background: universalTheme.background,
  headingTextColor: '#000000',
  buttonTextColor: 'white'
};

export const preStepThroughTheme = {
  background: universalTheme.background,
  headingTextColor: '#000000',
  buttonTextColor: 'white'
};

export const stepThroughTheme = {
  background: universalTheme.background,

  headingTextColor: '#000000',
  buttonTextColor: 'white',
  stepperActiveColor: 'white',
  stepperInactiveColor: '#000000',
  stepperActiveBackgroundColor: '#000000',
  inputOutlineColor: '#000000',
  labelTextColor: '#000000',
  inputTextColor: '#000000'
};

export const negotiationPanelTheme = {
  background: universalTheme.background,
  boxBackground: 'rgba(27, 28, 49, 0.05)',
  headingTextColor: '#000000',
  highlightColor: 'white',
  green: '#7BFF66',
  counterOfferBackgroundColor: '#F6C547',
  buttonTextColor: 'white',
  negotiationButtonColor: '#7BFF66'
  // stepperActiveColor: 'white',
  // stepperInactiveColor: '#000000',
  // stepperActiveBackgroundColor: '#000000',
  // inputOutlineColor: '#000000',
  // labelTextColor: '#000000',
  // inputTextColor: '#000000'
};

export const alertModalTheme = {
  background: universalTheme.background,
  headingTextColor: '#000000',
  textColor: '#000000',
  buttonTextColor: 'white'
};

export const loadingScreenTheme = {
  background: universalTheme.background,
  headingTextColor: '#000000',
  textColor: '#000000'
};

export const contractTheme = {
  background: universalTheme.background,
  modalBackground: '#1C1B1F',
  sideBackground: universalTheme.background,
  backButtonColor: '#000000',
  buttonTextColor: 'white',
  headingTextColor: 'white',
  textColor: 'white',
  contractOutsideBackground: '#F3F3F4',
  fontBackground: '#1E2029',
  signContractButtonBackgroundColor: 'white',
  signContractButtonTextColor: '#000000'
};

export const verifyEmailTheme = {
  background: universalTheme.background,
  modalBackground: '#CBD5E0',
  modalHeadingColor: '#000000',
  modalBodyColor: 'black',
  headingTextColor: '#000000',
  textColor: '#000000',
  submitButtonBackground: 'white',
  inputOutlineColor: '#000000',
  buttonTextColor: 'white',
  buttonBackgroundColor: '#000000'
};

export const V2Theme = defineStyle({
  primary: '#D1FD4D',
  primaryHover: '#B9E044',
  secondary: '#343330',
  secondaryHover: '#21201E',
  neutral: '#E8E8E8',
  neutralHover: '#C7C7C7',
  navbar: {
    background: '#141414'
  },
  mainBackground: '#F8F8F8'
});

export const V2TextStyles = defineStyle({
  button: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase' as const
  },
  base: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '145%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px'
  },
  xxs: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '145%',
    letterSpacing: '0.1px'
  },
  xs: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '145%',
    letterSpacing: '0.1px'
  },
  T1: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '0.1px'
  },
  T2: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.1px'
  },
  XL: {
    fontFamily: 'PPNeueMontreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
    letterSpacing: '0.1px'
  }
});

export default theme;
