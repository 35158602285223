import {
  Menu as _Menu,
  MenuButton as _MenuButton,
  MenuList as _MenuList,
  MenuItem as _MenuItem,
  MenuDivider as _MenuDivider,
  Box
} from '@chakra-ui/react';
import { V2TextStyles } from 'src/theme/theme';

export const Menu: typeof _Menu = (props) => {
  return <_Menu {...props} />;
};

export const MenuButton: typeof _MenuButton = (props) => {
  return <_MenuButton {...props} />;
};

export const MenuList: typeof _MenuList = (props) => {
  return (
    <_MenuList
      padding={'4px'}
      border="1px"
      borderColor={'#202020'}
      rounded={'0px'}
      bg="black"
      {...props}
    />
  );
};

export const MenuItem: typeof _MenuItem = (props) => {
  return (
    <_MenuItem
      bg="black"
      css={V2TextStyles.xs}
      _hover={{ bg: '#222', color: '#D1FD4D', '& div': { opacity: 1 } }}
      {...props}
      icon={
        props.icon ? <Box css={{ opacity: 0.5 }}>{props.icon}</Box> : undefined
      }
    />
  );
};

export const MenuDivider: typeof _MenuDivider = (props) => {
  return <_MenuDivider bg="#444" borderColor="#444" {...props} />;
};
