import { FlexProps, Flex } from '@chakra-ui/react';
import React from 'react';
import { loadingScreenTheme } from 'src/theme/theme';

export interface LoadScreenProps extends FlexProps {
  label?: string;
}

export const LoadScreen: React.FC<LoadScreenProps> = ({ label, ...props }) => {
  const bg = loadingScreenTheme.background;

  return (
    <Flex
      height="100vh"
      background={bg}
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" alignItems="center">
        <svg
          width="107"
          height="107"
          viewBox="0 0 107 107"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            animation: 'jump 1s ease-in-out infinite'
          }}
        >
          <path
            d="M107 55.621C105.887 84.1862 82.3692 107 53.5205 107C23.9615 107 0 83.0477 0 53.5C0 23.9523 23.9615 0 53.5205 0C82.3692 0 105.887 22.8151 107 51.379H52.5543C52.0292 48.8706 50.5926 46.6457 48.5221 45.1344C46.4515 43.6231 43.894 42.9326 41.3439 43.1965C38.7937 43.4603 36.432 44.6597 34.7149 46.5629C32.9979 48.466 32.0476 50.9378 32.0476 53.5006C32.0476 56.0634 32.9979 58.5353 34.7149 60.4384C36.432 62.3416 38.7937 63.541 41.3439 63.8048C43.894 64.0686 46.4515 63.3782 48.5221 61.8669C50.5926 60.3556 52.0292 58.1307 52.5543 55.6222L107 55.621Z"
            fill="url(#paint0_linear_284_2204)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_284_2204"
              x1="107"
              y1="54.5094"
              x2="1.07154e-07"
              y2="54.5094"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#000000" offset="0">
                <animate
                  attributeName="offset"
                  values="-2.5;1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="0.5" stopColor="#666666">
                <animate
                  attributeName="offset"
                  values="-0.5;2"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="0.5" stopColor="#666666">
                <animate
                  attributeName="offset"
                  values="-0.5;2"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop stopColor="#000000" offset="1">
                <animate
                  attributeName="offset"
                  values="0;2.5"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
        </svg>
        <style>
          {`
            @keyframes jump {
              0% { transform: translateY(0); }
              50% { transform: translateY(-10px); }
              100% { transform: translateY(0); }
            }
          `}
        </style>
      </Flex>
    </Flex>
  );
};
